import React, { useRef } from 'react';
import styles from './Footer.module.css';
import Logo from "../../images/Frame 1.svg";
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";
import location from "../../images/location_on.svg";
import call from "../../images/call.svg";
import mail from "../../images/email.svg"; 
import facebook from "../../images/ic_baseline-facebook.svg";
import insta from "../../images/ri_instagram-fill.svg";
import twitter from "../../images/mdi_twitter.svg";

const Footer = () => {
	const navigate = useNavigate();
	const form = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();

		await emailjs.sendForm('service_mgfv6x2', 'template_oyjfdgl', form.current, {
			publicKey: 'JNkKjp0D9WcgjrMek',
		  });
		  alert("Thanks for Subscribing!");
	};

	return (
    	<footer className={`${styles.Footer} container-fluid py-2`}>
			<div className='row'>
				<div className={`${styles.logoSection} col-2 text-start`}>
					<img src={Logo} alt='logo' onClick={() => navigate('/')} />
					<p>
						Your Quick Stop for Quality Convenience
					</p>
					<div>
						<button type='button' className='btn rounded-circle' onClick={() => {window.location.href="https://www.facebook.com/TapnGoCampbellfield/"}}><img src={facebook} /></button>
						<button type='button' className='btn rounded-circle' onClick={() => {window.location.href="https://www.instagram.com/tapandgo.campbellfield?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="}}><img src={insta} /></button>
						<button type='button' className='btn rounded-circle'><img src={twitter} /></button>
					</div>
				</div>
				<div className={`${styles.quickLinkSection} col-6 text-start`}>
					<div className='row'>
						<div className='col-5'>
							<h1>Get in Touch</h1>
							<ul>
								<li className='d-flex column-gap-2' onClick={() => {window.location.href="https://maps.app.goo.gl/XNnFV1rPaheCVqjHA"}}><img src={location} /> 1470-1474 Sydney Rd, Campbellfield VIC 3061,<br /> Australia</li>
								<li><a href='mailto:hello@tagcw.com.au' style={{ textDecoration: 'none', color: 'inherit' }} className='d-flex column-gap-2'><img src={mail} />hello@tagcw.com.au</a></li>
								<li><a href='tel:+61 410 910 644' style={{ textDecoration: 'none', color: 'inherit' }} className='d-flex column-gap-2'><img src={call} />+61 410 910 644</a></li>
							</ul>
						</div>
						<div className='col-3'>
							<h1>Support</h1>
							<ul>
								<li>Service FAQ</li>
								<li>User Policy</li>
								<li>Privacy Policy</li>
								<li>Members Policy</li>
								<li>Customer Support</li>
							</ul>
						</div>
						<div className='col-3'>
							<h1>Company</h1>
							<ul>
								<li onClick={() => navigate('/about')}>About Us</li>
								<li onClick={() => navigate(`/#services`)}>Our Services</li>
								<li>Pricing Plan</li>
							</ul>
						</div>
					</div>
				</div>
				<div className={`${styles.newsletterSection} col-3 text-start`}>
					<h1>Join a Newsletter</h1>
					<form ref={form} onSubmit={handleSubmit}>
						<label for='newsletterEmail' className='form-label'>Your Email</label>
						<input type='email' 
							className='form-control' 
							placeholder='Enter Your Email' 
							name='email'
							id='newsletterEmail' />
						<button type='submit' className='btn rounded-pill' onClick={handleSubmit}>Subscribe</button>
					</form>
				</div>
			</div>
      		<div className="d-flex flex-row align-items-center justify-content-center">
        		<p className='text-start px-4'>Copyright &copy; {new Date().getFullYear()} All Rights Reserved.</p>
      		</div>
    	</footer>
  	);
};

export default Footer;
