import React from "react";
import styles from "./MobileAbout.module.css";
import mobileAboutBanner from "../../images/mobile_about_banner.png";
import mobileSqueaky from "../../images/mobile_squeaky.png";
import mobiledog from "../../images/mobileDog.png";
import mobileMachine from "../../images/mobilemachine.png";
import Icon1 from "../../images/ion_location-outline.png";
import Icon2 from "../../images/icons8-water-hose-64 1.png";
import Icon3 from "../../images/icons8-contactless-64 (2) 1.png";
import Icon4 from "../../images/icons8-coffee-64 1.png";
import Icon5 from "../../images/icons8-one-free-100 1.png";
import Icon6 from "../../images/icons8-loyalty-100 1.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import MobileFooter from "../../components/Footer/MobileFooter";

const MobileAbout = () => {
  const navigate = useNavigate();
  return (
    <>
      <MobileNavbar />
      {/* Hero Section Start */}
      <div className={`${styles.heroSection} container-fluid`}>
        <h1 className="poppins">
          Why make multiple <br /> stop around town?
        </h1>
        <p>When you can just your errands from under one roof!</p>
        <AnchorLink href="#services">
        <button>Check The Services</button>
        </AnchorLink>
      </div>
      <div className="container">
        <img
          src={mobileAboutBanner}
          className="img-fluid"
          style={{ marginTop: "-30px" }}
        />
      </div>
      {/* Hero Section End */}

      <div className={`${styles.infoSection} mt-5`} id="services">
        <h2 className="poppins">
          Get your car squeaky <br /> clean!
        </h2>
        <div style={{ backgroundImage: `url(${mobileSqueaky})` }}></div>
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Take control of your car's shine at Tap & Go!
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Our self-service car wash stations are equipped with <br /> everything
          you need for a spotless finish. From foaming <br /> brushes to
          high-pressure hoses, our user-friendly facilities <br /> make it easy
          to give your vehicle the care it deserves.
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Stop by and experience the satisfaction of a self car wash <br /> that
          puts you in the driver's seat of cleanliness.
        </span>
        <button className="mt-5" onClick={() => navigate('/mobilecarwash')}>Check The Services</button>
      </div>

      <div className={`${styles.infoSection} mt-5`}>
        <h2 className={`${styles.secondHeading} poppins`}>
          Give your pet a beautiful <br /> wash and scrub!
        </h2>
        <div style={{ backgroundImage: `url(${mobiledog})` }}></div>
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Whether you have a tiny poodle or a large great dane, we <br /> have
          all the space and supplies you’ll need to give your fluffy <br />{" "}
          buddy a good bath.
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          We understand you may have a busy schedule, so we keep <br /> our
          self-service dog wash open 24x7!
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          We also have a spacious drying bay for your pets to walk <br /> around
          while they get dry.
        </span>
        <button className="mt-5" onClick={() => navigate('/mobiledogwash')}>Check The Services</button>
      </div>

      <div className={`${styles.infoSection} mt-5`}>
        <h2 className="poppins">
          Let us take care of your <br /> laundry needs!
        </h2>
        <div style={{ backgroundImage: `url(${mobileMachine})` }}></div>
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Is your laundry starting to pile up?
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Visit Tap & Go to use one of our washers and dryers ranging <br />
          from small single-load machines, perfect for washing daily <br />{" "}
          clothes, to extra large capacity washers for large blankets and <br />
          comforters machines for heavier loads.
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          When your busy schedule leaves you no time to do your <br />
          laundry, we’re happy to do it for you.
        </span>
        <button className="mt-5" onClick={() => navigate('/mobilelaunderette')}>Check The Services</button>
      </div>

      {/* Why Choose Us Start */}
      <div className={`${styles.chooseUsContainer} mt-5 container`}>
        <h2 className="poppins mb-5 mt-5 ">Why Choose Us?</h2>
        <div className={`${styles.chooseCard}`}>
          <img src={Icon1} />
          <h2 className="poppins">One Stop Destination</h2>
          <p className="poppins">
            Effortless errands: laundry, pet grooming, and an automated car
            wash—all while sipping coffee!
          </p>
        </div>
        <div className={`${styles.chooseCard} mt-5`}>
          <img src={Icon2} />
          <h2 className="poppins">Top Notch Equipment</h2>
          <p className="poppins">
            Experience top-tier service with our cutting-edge equipment,
            ensuring a guarantee of quality for all your needs!
          </p>
        </div>
        <div className={`${styles.chooseCard} mt-5`}>
          <img src={Icon3} />
          <h2 className="poppins">Contactless Service</h2>
          <p className="poppins">
            Stay safe with Tap & Go and enjoy contactless service & payment for
            your convenience in the era of COVID-19!
          </p>
        </div>
        <div className={`${styles.chooseCard} mt-5`}>
          <img src={Icon4} />
          <h2 className="poppins">Great Coffee</h2>
          <p className="poppins">
            Indulge in the finest brews as you wait! Exceptional coffee,
            seamlessly paired with convenience at your fingertips.
          </p>
        </div>
        {/* <div className={`${styles.chooseCard} mt-5`}>
          <img src={Icon5} />
          <h2 className="poppins">Buy 1, Get 1 FREE</h2>
          <p className="poppins">
            Double the shine, half the cost! Pay for 1 car wash and get your
            second absolutely free at our one-stop convenience hub!
          </p>
        </div> */}
        <div className={`${styles.chooseCard} mt-5`}>
          <img src={Icon6} />
          <h2 className="poppins">Earn Loyalty Points</h2>
          <p className="poppins">
            Unlock exclusive benefits with our loyalty program! Join us today
            and start earning valuable points with every visit.
          </p>
        </div>
      </div>
      {/* Why Choose Us End */}

      {/* Local Business Start */}
      <div className="container mb-5">
        <div className={`${styles.localBusinessContainer}`}>
          <span className="poppins">Community</span>
          <h2 className="poppins">
            Supporting Local <br /> Business
          </h2>
          <p className="poppins">
            "As proud members of the local community, we're committed to
            supporting fellow businesses. Soon, we'll have a dedicated board for
            local companies and entrepreneurs to showcase their flyers, posters,
            or business cards. If you're a local business owner looking to
            promote your products or services, drop by and chat with us. We'd be
            delighted to feature your business on our board for our customers to
            discover!"
          </p>
        </div>
      </div>
      {/* Local Business End */}
      <MobileFooter />
    </>
  );
};
export default MobileAbout;
