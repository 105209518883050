import React, { useState, useRef } from "react";
import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import 'animate.css';
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import pin from "../../images/map-pin.png";
import Video from "../../media/Home Page.mp4";
import CarWash from "../../images/car and splashes.png"
import DogSpa from "../../images/dog with bubbles.png";
import Laundary from "../../images/machine w bubbles.png";
import TapGo from "../../images/Group 110.png";
import CarT from "../../images/Rectangle 247.png";
import Vector from "../../images/quoteSign.png";
import Direction from "../../images/carbon_direction-loop-right.png";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Slider from "react-slick";
import MobileHome from "../../mobile_pages/Home/MobileHome"; 
import { APIProvider, AdvancedMarker, InfoWindow, Map } from "@vis.gl/react-google-maps";

const Home = () => {
    
    const [videoPlaying, setVideoPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const playerRef = useRef(null);
    const navigate = useNavigate();

    const togglePause = () => {

        if (videoPlaying) {
            setVideoPlaying(false);
            if (playerRef.current) {
                playerRef.current.seekTo(0);
            }
        } else {
            setVideoPlaying(true);
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const mainPosition = { lat: -37.68637725330796, lng: 144.9587401963364 };
    const [ openInfo, setInfo ] = useState(false);

    const HeroCarousel = () => {
        const [ activeSlide, setActiveSlide ] = useState(0);
        let sliderRef = useRef(null);
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            afterChange: (current, next) => {
              setActiveSlide(current);
            },
        };

        return (
            <div className={`${styles.heroSection} container-fluid`}>
                <Slider 
                    ref={slider => {
                        sliderRef = slider;
                      }}

                    {...settings} >
                    <div>
                        <div className="d-flex">
                            <div className="col text-start" style={{ marginInlineStart: '8%' }}>
                                <h1>Ultimate Care <br /> for Your Car</h1>
                                <p>Revitalize your ride at Tap & Go Car Wash! 
                                    Our cutting-edge technology ensures leaving your vehicle shining and protected. 
                                    Experience the ultimate car care – because your journey deserves a spotless start.
                                </p>
                                <button type="button" className="btn rounded-pill" onClick={() => navigate('/carwash')}>See the Difference Today!</button>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <img src={CarWash} alt="CarWash" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex">
                            <div className="col text-start" style={{ marginInlineStart: '8%' }}>
                                <h1>Making your Dog’s Bath-time a Breeze.</h1>
                                <p>Pamper your furry friend at our Dog Wash! 
                                    A delightful wash experience for a tail-wagging good time.
                                </p>
                                <button type="button" className="btn rounded-pill" onClick={() => navigate('/dogwash')}>Step into Freshness</button>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <img src={DogSpa} alt="CarWash" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex">
                            <div className="col text-start" style={{ marginInlineStart: '8%' }}>
                                <h1>Your Local Laundry Solution</h1>
                                <p>Experience laundry made easy at our launderette. 
                                    From daily clothes to bulky blankets, 
                                    we've got your laundry needs covered with convenience and care.
                                </p>
                                <button type="button" className="btn rounded-pill" onClick={() => navigate('/launderette')}>Experience the Spotless Clean</button>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <img src={Laundary} alt="CarWash" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Slider>
                <div className={`${styles.carouselIndicators} container`}>
                    <div 
                        className={`${
                            activeSlide === 0
                                ? `${styles.activeIndicator}` : `${styles.inactiveIndicator}`
                            } rounded-circle`} 
                        onClick={() => sliderRef.slickGoTo(0)}>
                    </div>
                    <div 
                        className={`${
                            activeSlide === 1
                                ? `${styles.activeIndicator}` : `${styles.inactiveIndicator}`
                            } rounded-circle`}
                            onClick={() => sliderRef.slickGoTo(1)}>
                    </div>
                    <div 
                        className={`${
                            activeSlide === 2
                                ? `${styles.activeIndicator}` : `${styles.inactiveIndicator}`
                        } rounded-circle`}
                        onClick={() => sliderRef.slickGoTo(2)}>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
        <div className={`${styles.desktopHome}`}>
        <Navbar />
        <div className={`${styles.videoSection} container-fluid`}>
            <div className="card border-0">
                <ReactPlayer
                    ref={playerRef}
                    url={Video}
                    width={'100%'}
                    height={'100%'}
                    controls={false}
                    playing={videoPlaying}
                    loop={true}
                    muted={isMuted}
                    className="animate_animated animate__fadeIn animate__fadeOut animate_slower"
                />
                <div className="card-img-overlay">
                        <button
                            type="button"
                            className="btn btn-light rounded-circle"
                            onClick={togglePause}
                        >
                            {videoPlaying ? (
                                <FaPause style={{ fill:"#D02329", width:"25px", height:"25px" }}/> 
                                ) : ( 
                                <FaPlay style={{ fill:"#D02329", width:"25px", height:"25px" }}/>
                            )}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-light rounded-circle"
                            onClick={toggleMute}
                        >
                            {!isMuted ? ( 
                                <GoUnmute style={{ fill:"#FFFF", width:"25px", height:"25px" }}/> 
                                ) : ( 
                                <GoMute style={{ fill:"#FFFF", width:"25px", height:"25px" }}/>
                            )}
                        </button>
                </div>
            </div>
        </div>
        <HeroCarousel />
        <div className={`${styles.open24x7} container`}>
            <img src={TapGo} alt="tapgo" className="img-fluid" />
        </div>
        <div className={`${styles.serviceSection} container`} id="services">
            <h1>
                Our Services
            </h1>
            <div className="row row-cols-1 row-cols-md-3">
                <div className="col">
                    <div class="card text-bg-dark" onClick={() => navigate('/carwash')}>
                        <h1 class="card-title" style={{ color: "#fff" }}>Car Wash</h1>
                        <p class="card-text" style={{ color: "#FFFFFFCC" }}>Elevate your drive with Tap & Go's <br /> car wash. 
                            Our user-friendly facilities <br /> and cutting-edge equipment  <br /> empower you to give your vehicle <br /> 
                            the attention it deserves.
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div class="card text-bg-info" onClick={() => navigate('/dogwash')}>
                        <h1 class="card-title" style={{ color: "#fff" }}>Dog Wash</h1>
                        <p class="card-text" style={{ color: "#FFFFFFCC" }}>Indulge your furry friend at Tap & Go <br /> 
                            Dog Wash! Our self-service stations, <br />
                            designed for canine comfort, provide <br /> the tools and space you need to <br /> pamper your pet.
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div class="card text-bg-light" onClick={() => navigate('/launderette')}>
                        <h1 class="card-title" style={{ color: "#333333" }}>Launderette</h1>
                        <p class="card-text" style={{ color: "#333333CC" }}>Transform your laundry routine at <br /> 
                            Tap & Go Launderette! With our <br /> self-service facilities, 
                            tackle loads <br /> big and small with ease.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className={`${styles.testimonialSection} container`}>
            <h1>
                Testimonials
            </h1>
            <div className="row">
                <div className="col-5 text-start">
                    <div className="card text-bg-light">
                        <div className="card-header border-0" style={{ background: "unset" }}>
                            <img src={Vector} alt="quote" className="img-fluid" />
                        </div>
                        <div className="card-body">
                            <p>
                                I have been a loyal customer for years, and I'm always impressed with the attention to detail. 
                                My car looks brand new every time I get to wash my car here.
                            </p>
                            <small>Robert Gorial</small>
                        </div>
                    </div>
                    <div className="card text-bg-light">
                        <div className="card-header border-0" style={{ background: "unset" }}>
                            <img src={Vector} alt="quote" className="img-fluid" />
                        </div>
                        <div className="card-body">
                            <p>
                                This would have to be by far one of the best drive through car washes Ive been to in Melbourne so far. 
                                The attendant is really helpful professional and courteous. 
                                Above all the Laserwash is super quality and car has been clean for days now. They also have great fragrances. 
                                Best part about it they got a drive through Coffee shop next door as well. 
                                Well done guys VERY happy thanks so much Tap and Go!
                            </p>
                            <small>Robert Gorial</small>
                        </div>
                    </div>
                    <div className="card text-bg-light">
                        <div className="card-header border-0" style={{ background: "unset" }}>
                            <img src={Vector} alt="quote" className="img-fluid" />
                        </div>
                        <div className="card-body">
                            <p>
                                This would have to be by far one of the best drive through car washes Ive been to in Melbourne so far. 
                                The attendant is really helpful professional and courteous. 
                                Above all the Laserwash is super quality and car has been clean for days now. They also have great fragrances. 
                                Best part about it they got a drive through Coffee shop next door as well. 
                                Well done guys VERY happy thanks so much Tap and Go!
                            </p>
                            <small>Robert Gorial</small>
                        </div>
                    </div>
                </div>
                <div className="col-7">
                    <img src={CarT} alt="cart" className="img-fluid" />
                </div>
            </div>
        </div>
        <div className={`${styles.poster} container`}>
            <div className="card">
                <button type="button" className="btn btn-danger rounded-pill" onClick={() => navigate('/enquiry')}>Enquire Now</button>
            </div>
        </div>
        <div className={`${styles.visitSection} container`}>
            <div className="row">
                <div className="col-12 col-md-6 text-start">
                    <h1>
                        Visit Us!
                    </h1>
                    <p>
                        "Visit us today at Tap & Go, 
                        your neighborhood hub for convenience and quality services in Campbellfield, VIC, Australia. 
                        Our strategic location in the heart of Campbellfield allows us to serve our community better and ensure that our services are easily accessible. 
                        We are proud to be a locally owned and operated business, 
                        and we take pride in enhancing the routines of our customers with our comprehensive services."
                    </p>
                    <button type="button" className="btn rounded-pill" onClick={() => {window.location.href="https://maps.app.goo.gl/XNnFV1rPaheCVqjHA"}}>
                        <img src={Direction} alt="arrow" className="img-fluid" style={{ marginInlineEnd: '12px' }} />
                        Get Directions
                    </button>
                </div>
                <APIProvider apiKey={'AIzaSyBZoct8K8bPkVmIPKWSrzw_TC4Iwth-cic'}>
                    <div className={` ${styles.mapSection} col-12 col-md-6`}>
                        <Map defaultZoom={18} defaultCenter={mainPosition} mapId={'e6e8821d00ab469d'}>
                            <AdvancedMarker position={mainPosition} onClick={() => setInfo(true)}>
                                <img src={pin} />
                            </AdvancedMarker>
                            {openInfo && <InfoWindow position={mainPosition} onCloseClick={() => setInfo(false)}>
                                <div className={`${styles.infoWindow}`}>
                                    <p>1470-1474 Sydney Rd, Campbellfield VIC 3061, Australia</p>
                                </div>
                            </InfoWindow>}
                        </Map>
                    </div>
                </APIProvider>
            </div>
        </div>
        <Footer />
        </div>
        <div className={`${styles.mobileHome}`}>
            <MobileHome />
        </div>
        </>
    );
};

export default Home;