import React, { useState } from "react";
import Logo from "../../images/Frame 1.svg";
import Menu from "../../images/tabler_menu-deep.svg";
import MenuW from "../../images/tabler_menu-deep.png";
import Arrow from "../../images/carbon_direction-loop-right.png";
import ArrowW from "../../images/carbon_direction-loop-right (dark).png";
import { useNavigate } from "react-router-dom";

const MobileNavbar = ({ whiteBackground = false }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const buttonStyle = {
    padding: "5px 15px",
    border: "1px solid white",
    textAlign: "center",
    borderRadius: "100px",
    background: "transparent",
    color: "white",
    fontSize: "11px",
  };
  const buttonStyleW = {
    padding: "5px 15px",
    border: "1px solid black",
    textAlign: "center",
    borderRadius: "100px",
    background: "transparent",
    color: "black",
    fontSize: "11px",
  };
  const navbarStyle = {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.90)",
    zIndex: "999999",
    display: open ? "block" : "none",
  };
  return (
    <>
      {whiteBackground ? (
        <div>
          <div>
            <div className="container-fluid d-flex justify-content-between align-items-center pt-4 mb-4">
              <img src={MenuW} onClick={() => setOpen(!open)} />
              <img src={Logo} width={60} />
              <button style={buttonStyleW} onClick={() => navigate('/')}>Our Services</button>
              <button
                style={buttonStyleW}
                onClick={() => {
                  window.location.href =
                    "https://maps.app.goo.gl/ekDaEG3oGcy2gUjk6";
                }}
              >
                <img
                  src={ArrowW}
                  alt="arrow"
                  width={16.18}
                  height={16.18}
                  className="me-1"
                />
                Get Directions
              </button>
            </div>
          </div>
          <div
            style={navbarStyle}
            className={`${
              open ? "animate__animated animate__slideInLeft" : ""
            }`}
          >
            <div className="container d-flex justify-content-around align-items-center mt-4 mb-5">
              <img src={Logo} width={80} />
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilehome")}
              >
                Home
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobileabout")}
              >
                About Us
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilecarwash")}
              >
                Car Wash
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilelaserwash")}
              >
                Laser Wash 360
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobiledogwash")}
              >
                Dog Wash
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilelaunderette")}
              >
                Laundromat
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilebusiness")}
              >
                Local Communities
              </h3>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className="container-fluid d-flex justify-content-between align-items-center mt-4 mb-4">
              <img src={Menu} onClick={() => setOpen(!open)} />
              <img src={Logo} width={60} />
              <button style={buttonStyle} onClick={() => navigate('/')}>Our Services</button>
              <button style={buttonStyle}
                onClick={() => {
                  window.location.href =
                    "https://maps.app.goo.gl/ekDaEG3oGcy2gUjk6";
                }}
              >
                <img
                  src={Arrow}
                  alt="arrow"
                  width={16.18}
                  height={16.18}
                  className="me-1"
                />
                Get Directions
              </button>
            </div>
          </div>
          <div
            style={navbarStyle}
            className={`${
              open ? "animate__animated animate__slideInLeft" : ""
            }`}
          >
            <div className="container d-flex justify-content-around align-items-center mb-5">
              <img src={Logo} width={80} />
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilehome")}
              >
                Home
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobileabout")}
              >
                About Us
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilecarwash")}
              >
                Car Wash
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilelaserwash")}
              >
                Laser Wash 360
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobiledogwash")}
              >
                Dog Wash
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilelaunderette")}
              >
                Laundromat
              </h3>
              <h3
                className="poppins mb-4"
                onClick={() => navigate("/mobilebusiness")}
              >
                Local Communities
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNavbar;
