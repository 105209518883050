import "./App.css";
import "./fonts.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import CarWash from "./pages/CarWash/CarWash";
import Enquiry from "./pages/Enquiry/Enquiry";
import DogWash from "./pages/DogWash/DogWash";
import About from "./pages/About/About";
import LaserWash from "./pages/LaserWash/LaserWash";
import Launderette from "./pages/Launderette/Launderette";
import Community from "./pages/Community/Community";
import MobileHome from "./mobile_pages/Home/MobileHome";
import MobileAbout from "./mobile_pages/About/MobileAbout";
import MobileCarWash from "./mobile_pages/CarWash/MobileCarWash";
import MobileEnquiry from "./mobile_pages/Enquiry/MobileEnquiry";
import MobileBusiness from "./mobile_pages/LocalBusiness/MobileBusiness";
import MobileDogWash from "./mobile_pages/DogWash/MobileDogWash";
import MobileLaserWash from "./mobile_pages/LaserWash/MobileLaserWash";
import MobileLaunderette from "./mobile_pages/Launderette/MobileLaunderette";

function App() {  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/carwash" element={<CarWash />} />
        <Route path="/dogwash" element={<DogWash />} />
        <Route path="/laserwash" element={<LaserWash />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/launderette" element={<Launderette />} />
        {/* <Route path="/community" element={<Community />} /> */}
        <Route path="/mobilehome" element={<MobileHome />} />
        <Route path="/mobileabout" element={<MobileAbout />} />
        <Route path="/mobilecarwash" element={<MobileCarWash />} />
        <Route path="/mobileenquiry" element={<MobileEnquiry />} />
        <Route path="/mobilebusiness" element={<MobileBusiness />} />
        <Route path="/mobiledogwash" element={<MobileDogWash />} />
        <Route path="/mobilelaserwash" element={<MobileLaserWash />} />
        <Route path="/mobilelaunderette" element={<MobileLaunderette />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
