import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import LogoB from "../../images/LogoB.svg";
import Logo from "../../images/Frame 1.svg";
import Menu from "../../images/tabler_menu-deep.svg";
import MenuD from "../../images/tabler_menu-deep.png";
import Arrow from "../../images/carbon_direction-loop-right.png";
import ArrowD from "../../images/carbon_direction-loop-right (dark).png";
import Tiktok from "../../images/Vector.svg";
import TiktokD from "../../images/ic_baseline-tiktok.png";
import Insta from "../../images/Vector (1).svg";
import InstaD from "../../images/ri_instagram-fill.png";
import Facebook from "../../images/Vector (2).svg";
import FacebookD from "../../images/gg_facebook.png";

const Navbar = ({ whiteBackground = false }) => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  const navigate = useNavigate();

  return (
    <div>
      {whiteBackground ? (
        <nav className={`navbar ${styles.NavbarW}`}>
          <div className="d-flex">
            <div className="col">
              <button
                className=" btn border-0 p-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#Menu"
                onClick={toggleMenu}
              >
                <span>
                  <img src={MenuD} alt="menu" className="img-fluid" />
                </span>
              </button>
              <a
                className="navbar-brand"
                href="/"
                onClick={() => navigate("/")}
              >
                <img src={LogoB} alt="company-logo" width={81.06} height={47} />
              </a>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-dark rounded-pill d-md-none"
                onClick={() => navigate("/")}
              >
                Our Services
              </button>
              <button
                type="button"
                className="btn btn-outline-dark rounded-pill"
                onClick={() => {window.location.href="https://maps.app.goo.gl/ekDaEG3oGcy2gUjk6"}}
              >
                <img
                  src={ArrowD}
                  alt="arrow"
                  width={22}
                  height={22}
                  className="me-2"
                />
                Get Directions
              </button>
              <button
                type="button"
                className="btn btn-outline-dark rounded-circle d-none d-md-block"
              >
                <img src={TiktokD} alt="tiktok" />
              </button>
              <button
                type="button"
                className="btn btn-outline-dark rounded-circle d-none d-md-block"
                onClick={() => {window.location.href="https://www.instagram.com/tapandgo.campbellfield?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="}}
              >
                <img src={InstaD} alt="insta" />
              </button>
              <button
                type="button"
                className="btn btn-outline-dark rounded-circle d-none d-md-block"
                onClick={() => {window.location.href="https://www.facebook.com/TapnGoCampbellfield/"}}
              >
                <img src={FacebookD} alt="facebook" />
              </button>
            </div>
          </div>
        </nav>
      ) : (
        <nav className={`navbar ${styles.NavbarB}`}>
          <div className="d-flex">
            <div className="col">
              <button
                className=" btn border-0 p-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#Menu"
                onClick={toggleMenu}
              >
                <span>
                  <img src={Menu} alt="menu" />
                </span>
              </button>
              <a
                className="navbar-brand"
                href="/"
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="company-logo" width={81.06} height={47} />
              </a>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-light rounded-pill d-md-none"
                onClick={() => navigate("/")}
              >
                Our Services
              </button>
              <button
                type="button"
                className="btn btn-outline-light rounded-pill"
                onClick={() => {window.location.href="https://maps.app.goo.gl/ekDaEG3oGcy2gUjk6"}}
              >
                <img
                  src={Arrow}
                  alt="arrow"
                  width={22}
                  height={22}
                  className="me-2"
                />
                Get Directions
              </button>
              <button
                type="button"
                className="btn btn-outline-light rounded-circle d-none d-md-block"
              >
                <img src={Tiktok} alt="tiktok" />
              </button>
              <button
                type="button"
                className="btn btn-outline-light rounded-circle d-none d-md-block"
                onClick={() => {window.location.href="https://www.instagram.com/tapandgo.campbellfield?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="}}
              >
                <img src={Insta} alt="insta" />
              </button>
              <button
                type="button"
                className="btn btn-outline-light rounded-circle d-none d-md-block"
                onClick={() => {window.location.href="https://www.facebook.com/TapnGoCampbellfield/"}}
              >
                <img src={Facebook} alt="facebook" />
              </button>
            </div>
          </div>
        </nav>
      )}
      <div
        className={`offcanvas offcanvas-start text-bg-dark ${styles.menu} ${
          showMenu ? "show" : ""
        }`}
        tabIndex="-1"
        id="Menu"
      >
        <div className="offcanvas-header">
          <img src={Logo} alt="logo" />
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={toggleMenu}
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/" onClick={() => navigate("/")}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about" onClick={toggleMenu}>
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/carwash"
                onClick={() => navigate("/carwash")}
              >
                Car Wash
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/laserwash"
                onClick={() => navigate("/laserwash")}
              >
                Laser Wash 360
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/dogwash" onClick={toggleMenu}>
                Dog Wash
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/launderette" onClick={toggleMenu}>
                Laundromat
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/community" onClick={toggleMenu}>
                Local Communities
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
