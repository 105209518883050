import React, { useRef, useState } from "react";
import styles from "./CarWash.module.css";
import LaserWash from "../../images/laserWash360Te.png";
import Splash from "../../images/Clip path group2.png";
import TickW from "../../images/Tick Icon.png";
import TickR from "../../images/Tick Icon (1).png";
import Image1 from "../../images/jetWash.png";
import Icon1 from "../../images/ic_baseline-do-not-touch.png";
import Icon2 from "../../images/icons8-hot-springs-100 (1) 1.png";
import Icon3 from "../../images/icons8-water-52 2.png";
import Icon4 from "../../images/icons8-hourglass-96 1.png";
import Icon5 from "../../images/icons8-car-cleaning-100 1.png";
import Icon6 from "../../images/icons8-vehicle-insurance-64 2.png";
import Group from "../../images/Group 122.png";
import BestValue from "../../images/Car Rental 2.png";
import Collage from "../../images/Group 113.png";
import Poster from "../../images/Rectangle 242.png";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ReactPlayer from "react-player";
import Video from "../../media/The LaserWash 360 Plus by PDQ.mp4";
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import MobileCarWash from "../../mobile_pages/CarWash/MobileCarWash";
import emailjs from "@emailjs/browser";

const CarWash = () => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const playerRef = useRef(null);
  const navigate = useNavigate();

  const toggleVideo = () => {
    if (!videoPlaying) {
      setVideoPlaying(true);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
    } else {
      setVideoPlaying(false);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  const [ quantity, setQuantity ] = useState();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send('service_mgfv6x2', 'template_oyjfdgl', {
        'quantity':quantity
      }, {
        publicKey: 'JNkKjp0D9WcgjrMek',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }

  return (
    <>
      <div className={`${styles.desktopCarwash}`}>
      <Navbar />
      <div className={`${styles.topSection} container`}>
        <div className="d-flex flex-row">
          <div className="col-10">
            <h1>
              Where Every Wash Revives Your Ride's Radiance!
            </h1>
            <p>
              Revitalize your ride at Tap & Go Car Wash! Our cutting-edge technology ensures leaving your vehicle shining and protected. Experience the ultimate car care – because your journey deserves a spotless start.
            </p>
            <AnchorLink href="#demovideo">
            <button type="button" className="btn rounded-pill">Check The Services</button>
            </AnchorLink>
          </div>
        </div>
      </div>
      <div className={`${styles.laserSection} container position-relative`}>
        <div className="card bg-light d-flex align-items-center justify-content-center p-5 m-5">
          <img src={LaserWash} alt="laserWash" className="img-fluid m-5" width={800} height={200}/>
          <button type="button" className="btn rounded-pill" onClick={() => {navigate('/laserwash')}}>Check More Details</button>
        </div>
        <img src={Splash} alt="splash" className="img-fluid" />
        <img src={Splash} alt="splash" className="img-fluid" />
        <img src={Splash} alt="splash" className="img-fluid" />
        <img src={Splash} alt="splash" className="img-fluid" />
      </div>
      <div className={`${styles.serviceSection} container d-none`}>
        <h1>Self-Serve Services</h1>
        <div className="row row-cols-3 g-4 text-start">
          <div className="col">
            <div className="card">
              <div
                className="card-header border-0"
                style={{ background: "unset" }}
              >
                <p>Turbo Vacuum</p>
                <h1 style={{ color: "#D02329" }}>$20</h1>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">Turbo Mode</li>
                  <li className="list-group-item">Normal Mode</li>
                  <li className="list-group-item">Fragrance Mode</li>
                </ul>
                <p>
                  Pick from our range of flavors:
                  <br />
                  New car, fresh mint and berry
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-bg-danger">
              <div
                className="card-header border-0"
                style={{ background: "unset" }}
              >
                <p>Deluxe Wash</p>
                <h1>$16</h1>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">
                    Side Blast
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Wheel Cleaning
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Pre-soak 1 - heated
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Pre-soak 2 - heated
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    High Pressure rinse
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Tri-colour foaming
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Spot free rinse
                    <span>
                      <img src={TickW} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div
                className="card-header border-0"
                style={{ background: "unset" }}
              >
                <p>Ultimate Wash</p>
                <h1 style={{ color: "#D02329" }}>$18</h1>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">
                    Side blast - Underbody wash
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Wheel cleaning
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Pre-soak 1 - heating
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Pre-soak 2 - heating
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    High Pressure rinse
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Super Sealent
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Spot free rinse
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                  <li className="list-group-item">
                    Extreme blowers
                    <span>
                      <img src={TickR} alt="tick" className="img-fluid" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.mainSection} container`} id="services">
        <h1>Self-Serve Bay</h1>
        <div className="row">
            <div className="col-6">
                <h1>Get your car squeaky clean!</h1>
                <p>Take control of your car's shine at Tap & Go!
                    <br/><br/>
                    Our self-service car wash stations are equipped with everything you need for a spotless finish. 
                    From foaming brushes to high-pressure hoses, our user-friendly facilities make it easy to give your vehicle the care it deserves. 
                    <br/><br/>
                    Stop by and experience the satisfaction of a self car wash that puts you in the driver's seat of cleanliness.
                </p>
            </div>
            <div className="col-6">
                <img src={Image1} alt="car-wash" className="img-fluid" />
            </div>
        </div>
      </div>

      <div className={`${styles.featureSection} container`}>
        <p>Car Wash Service</p>
        <h1>Features</h1>
        <div className="row text-start">
          <div className="col">
            <div className="card h-100">
              <img src={Icon1} className="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Contact Less</h5>
                <p className="card-text">
                  Experience hassle free service with our contactless feature –
                  a touchless, secure solution for a safer and more convenient
                  car wash experience at Tap & Go.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Icon2} class="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Hot Water Wash</h5>
                <p className="card-text">
                  {" "}
                  Our advanced technology uses heated water to dissolve grime,
                  providing an effective and thorough clean for your vehicle."
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Icon3} class="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Distilled Water</h5>
                <p className="card-text">
                  Enjoy a superior clean with our car wash's distilled water,
                  free from contaminants. Give your vehicle the pristine care it
                  deserves for a sparkling finish.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Icon4} class="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">No downtime</h5>
                <p className="card-text">
                  Uninterrupted service with our 'No Downtime' feature. Your
                  convenience is our priority, ensuring swift and seamless
                  experiences without any interruptions."
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Icon5} class="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Self-serve Bay</h5>
                <p className="card-text">
                  With our Self-Serve Bay – take control with foaming brushes
                  and high-pressure hoses, ensuring a personalized and thorough
                  cleaning experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Icon6} class="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Vehicle Safety</h5>
                <p className="card-text">
                  Our services are designed to enhance and maintain the safety
                  of your vehicle, ensuring a secure and worry-free driving
                  experience."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.carwash} container`} id="demovideo">
        <div className="card border-0">
          <ReactPlayer
            ref={playerRef}
            url={Video}
            width={"100%"}
            height={"100%"}
            controls={false}
            playing={videoPlaying}
            onEnded={handleVideoEnd}
            muted={isMuted}
          />
          <div className="card-img-overlay">
            <div className="d-flex flex-column">
              <div
                id="textOnVideo"
                className={`animate__animated ${
                  videoPlaying ? "animate__fadeOut" : "animate__fadeIn"
                }`}
              >
                <p>How it Works ?</p>
                <h1>Self-Serve Car Wash</h1>
                <p>
                  Make your vehicle shine by bringing it down to our
                  self-service car wash. You can count on our car wash stations
                  to be big enough to accomodate any sort of vehicle. Both wash
                  and vacuum services are available
                </p>
                <button
                  type="button"
                  className="btn rounded-pill"
                  onClick={toggleVideo}
                >
                  <FaPlay /> Play Demo Video
                </button>
              </div>
              <div id="buttonOnVideo">
                <button
                  type="button"
                  className={`btn btn-light rounded-circle animate__animated ${
                    videoPlaying ? "animate__fadeIn" : "animate__fadeOut"
                  }`}
                  onClick={toggleVideo}
                >
                  {videoPlaying ? (
                    <FaPause
                      style={{ fill: "#D02329", width: "25px", height: "25px" }}
                    />
                  ) : (
                    <FaPlay
                      style={{ fill: "#D02329", width: "25px", height: "25px" }}
                    />
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light rounded-circle"
                  onClick={toggleMute}
                >
                  {!isMuted ? (
                    <GoUnmute
                      style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                    />
                  ) : (
                    <GoMute
                      style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.buySection} container-fluid`}>
        <div className="row text-start">
          <div className="col-5">
            <img src={Group} alt="group" className="img-fluid" />
          </div>
          <div className="col-5">
            <img src={BestValue} alt="BestValue" className="img-fluid" />
            <p>
              Grab Our Exclusive Car Wash Coupon and <br />
              Enjoy a Sparkling Clean at Ride a Special <br /> Price!
            </p>
            <h1>Revitalize Your Ride for Less!</h1>
            <p>
              <strong>$150</strong> Pre-Paid Autowash
            </p>
            <label for="quantity">Quantity</label>
            <div className="input-group">
              <input type="number" id="quantity" name="quantity" onChange={(e) => setQuantity(e.target.value)}/>
              <span>For $300</span>
            </div>
            <button type="button" className="btn rounded-pill" onClick={sendEmail}>
              Buy Now
            </button>
          </div>
        </div>
      </div>
      <div className={`${styles.customerSection} container`}>
        <p>Happy Customers</p>
        <h1>Connect With Us</h1>
        <div>
          <img src={Collage} alt="collage" className="img-fluid" />
        </div>
      </div>
      <div className={`${styles.posterEnd} container`}>
        <img src={Poster} alt="poster" className="img-fluid" />
      </div>
      <Footer />
      </div>
      <div className={`${styles.mobileCarwash}`}>
        <MobileCarWash />
      </div>
    </>
  );
};

export default CarWash;
