import React, { useState, useRef } from "react";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import pin from "../../images/map-pin.png";
import styles from "./MobileHome.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import CarWashMobile from "../../images/carwash_mobile.png"; 
import DogWashMobile from "../../images/dog.png";
import LaundryMobile from "../../images/Group 127.png";
import Direction from "../../images/carbon_direction-loop-right.png";
import ReactPlayer from "react-player";
import MobileFooter from "../../components/Footer/MobileFooter";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import { useNavigate } from "react-router-dom";
import Video from "../../media/The LaserWash 360 Plus by PDQ.mp4";

const HeroSection = () => {
  const [videoPlaying, setVideoPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const playerRef = useRef(null);

  const togglePause = () => {

    if (videoPlaying) {
        setVideoPlaying(false);
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }
    } else {
        setVideoPlaying(true);
    }
  };

  const toggleMute = () => {
      setIsMuted(!isMuted);
  };

  return (
    <div className={`${styles.heroSection} container-fluid`}>
      <div className="card border-0">
        <ReactPlayer
          ref={playerRef}
          url={Video}
          width={'100%'}
          height={'100%'}
          controls={false}
          playing={videoPlaying}
          loop={true}
          muted={isMuted}
          className="animate_animated animate__fadeIn animate__fadeOut animate_slower"
        />
        <div className="card-img-overlay">
          <button
              type="button"
              className="btn btn-light rounded-circle"
              onClick={togglePause}
          >
              {videoPlaying ? (
                  <FaPause style={{ fill:"#D02329", width:"25px", height:"25px" }}/> 
                  ) : ( 
                  <FaPlay style={{ fill:"#D02329", width:"25px", height:"25px" }}/>
              )}
          </button>
          <button
              type="button"
              className="btn btn-outline-light rounded-circle"
              onClick={toggleMute}
          >
              {!isMuted ? ( 
                  <GoUnmute style={{ fill:"#FFFF", width:"25px", height:"25px" }}/> 
                  ) : ( 
                  <GoMute style={{ fill:"#FFFF", width:"25px", height:"25px" }}/>
              )}
          </button>
        </div>
      </div>
    </div>
  );
};

const OurServices = () => {
  const [slideActive, setSlideActive] = useState(0);
  const navigate = useNavigate();
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    afterChange: (current, next) => {
      setSlideActive(current);
    },
  };
  return (
    <div className={`${styles.ourServices} container`} id="services">
      <h1 className="text-center poppins">Our Services</h1>
      <Slider {...settings}>
        <div>
          <div
            className={`${styles.carWash}`}
            style={{
              backgroundImage: `url(${CarWashMobile})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h2 className="poppins">Car Wash</h2>
            <p>
              Elevate your drive with Tap & Go's car wash. Our user-friendly
              facilities and cutting-edge equipment empower you to give your
              vehicle the attention it deserves.
            </p>
            <button onClick={() => navigate('/mobilecarwash')}>Check The Service</button>
          </div>
        </div>
        <div>
          <div
            className={`${styles.dogWash}`}
            style={{
              backgroundImage: `url(${DogWashMobile})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "40px center",
            }}
          >
            <h2 className="poppins">Dog Wash</h2>
            <p>
              Indulge your furry friend at Tap & Go Dog Wash! Our self-service
              stations, designed for canine comfort, provide the tools and space
              you need to pamper your pet.
            </p>
            <button onClick={() => navigate('/mobiledogwash')}>Check The Service</button>
          </div>
        </div>
        <div>
          <div
            className={`${styles.laundry}`}
            style={{
              backgroundImage: `url(${LaundryMobile})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h2 className="poppins">Launderette</h2>
            <p>
              Transform your laundry routine at Tap & Go Launderette! With our
              self-service facilities, tackle loads big and small with ease.
            </p>
            <button onClick={() => navigate('/mobilelaunderette')}>Check The Service</button>
          </div>
        </div>
      </Slider>
      <div
        className={`${styles.scrollBarContainer} ${
          slideActive === 0
            ? "justify-content-start"
            : slideActive === 1
            ? "justify-content-center"
            : "justify-content-end"
        }`}
      >
        <div></div>
      </div>
    </div>
  );
};

const MobileHome = () => {
  const mainPosition = { lat: -37.68637725330796, lng: 144.9587401963364 };
  
  return (
    <div>
      <MobileNavbar />
      <HeroSection />

      {/* Ticker Start */}
      <div className={`${styles.tickerContainer}`}>
        <marquee className="poppins">
          OPEN 24x7 OPEN 24x7 OPEN 24x7 OPEN 24x7 OPEN 24x7 OPEN 24x7 OPEN 24x7
          OPEN 24x7 OPEN 24x7
        </marquee>
      </div>
      {/* Ticker End */}
      <OurServices />

      {/* Visit Us Start */}
      <div className={`${styles.visitUs} container`}>
        <h2 className="poppins">Visit Us!</h2>
        <p className="poppins">
          "Visit us today and experience the difference! Fully insured and
          strategically situated in the heart of Campbellfield, we take pride in
          being a locally owned and operated business dedicated to making your
          day a little bit easier. Enhance your routine with our comprehensive
          services – because convenience and quality are right in your
          neighborhood."
        </p>
        <button type="button" onClick={() => {window.location.href="https://maps.app.goo.gl/ekDaEG3oGcy2gUjk6"}}>
          <img src={Direction} />
          Get Directions
        </button>
        <APIProvider apiKey={'AIzaSyBZoct8K8bPkVmIPKWSrzw_TC4Iwth-cic'}>
          <div className={` ${styles.mapSection} mt-4`}>
            <Map defaultZoom={18} defaultCenter={mainPosition} mapId={'e6e8821d00ab469d'}>
              <AdvancedMarker position={mainPosition}>
                <img src={pin} />
              </AdvancedMarker>
            </Map>
          </div>
        </APIProvider>
      </div>
      {/* Visit Us End */}
      <MobileFooter />
    </div>
  );
};

export default MobileHome;
