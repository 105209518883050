import React, { useState, useRef } from "react";
import styles from "./LaserWash.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Laserlogo from "../../images/laserWash360.png";
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import Video from "../../media/Laser Wash 360.mp4";
import ReactPlayer from "react-player";
import MobileLaserWash from "../../mobile_pages/LaserWash/MobileLaserWash";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Laser360 = () => {
  const [videoPlaying, setVideoPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const playerRef = useRef(null);

    const togglePause = () => {

        if (videoPlaying) {
            setVideoPlaying(false);
            if (playerRef.current) {
                playerRef.current.seekTo(0);
            }
        } else {
            setVideoPlaying(true);
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

  return (
    <>
    <div className={`${styles.main}`}>
      <Navbar whiteBackground={true} />
        <div className={`${styles.topSection} container`}>
          <div className="row">
            <div className="col-6">
              <img src={Laserlogo} alt="laser360" className="img-fluid" />
            </div>
            <div className="col-6">
              <h1>Precision in Every Wash</h1>
              <p>
                Introducing LaserWash360 Technology for a Touchless, Efficient,
                and Superior Car Cleaning Experience.
              </p>
              <AnchorLink href="#pricing">
              <button type="button" className="btn rounded-pill">
                Check More Details
              </button>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className={`${styles.videoSection} container-fluid`}>
            <ReactPlayer
              ref={playerRef}
              url={Video}
              width={'100%'}
              height={'100%'}
              controls={false}
              playing={videoPlaying}
              loop={true}
              muted={isMuted}
            />
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-light rounded-circle"
                  onClick={togglePause}
                >
                  {videoPlaying ? (
                    <FaPause
                      style={{ fill: "#D02329", width: "25px", height: "25px" }}
                    />
                  ) : (
                    <FaPlay
                      style={{ fill: "#D02329", width: "25px", height: "25px" }}
                    />
                  )}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-outline-light rounded-circle"
                  onClick={toggleMute}
                >
                  {isMuted ? (
                    <GoMute
                      style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                    />
                  ) : (
                    <GoUnmute
                      style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                    />
                  )}
                </button>
              </div>
          </div>
        </div>
        <div className={`${styles.plans} container`} id="pricing">
          <h1>Choose a plan that suits your Carwash needs!</h1>
          <div className="row row-cols-1 row-cols-3">
            <div className="col">
              <div className="card h-100">
                <h5>Ultimate Wash</h5>
                <div>
                  <h1>
                    $28
                    <p>2 Washes</p>
                  </h1>
                  <h1 className="d-flex align-items-center justify-content-center" style={{ fontWeight: 'normal', lineHeight: 'auto', letterSpacing: '0', paddingBlockStart:'10%' }}>
                    (
                      <div className="d-flex flex-column p-0 m-0 g-0" style={{ fontSize:'20px', fontFamily: 'Poppins', fontWeight: 'bold', lineHeight: 'normal', letterSpacing: '0' }}>
                        $19
                        <p style={{ fontSize: '0.5em', paddingBlockStart: '0%', marginBlockEnd: '0', lineHeight: 'normal', letterSpacing: '0' }}>Per Wash</p>
                      </div>
                    ) 
                  </h1>
                </div>
                <ul>
                  <li>Underbody Wash</li>
                  <li>Hot Presoak</li>
                  <li>Power Rinse</li>
                  <li>Foaming Polish</li>
                  <li>Clearcoat Protectant</li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <h5>Deluxe Wash</h5>
                <h1>
                  $17
                  <p>Per Wash</p>
                </h1>
                <ul>
                  <li>Hot Presoak</li>
                  <li>Power Rinse</li>
                  <li>Hot Soap</li>
                  <li>Rinse & Shine</li>
                  <li>Spot Free Rinse</li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <h5>Night Owl</h5>
                <h1>
                  $14
                  <p>Per Wash</p>
                </h1>
                <div className="d-flex flex-column justify-content-end mt-5">
                  <h6 className="d-flex align-items-center justify-content-center">From: <h3>10 p.m.</h3></h6>
                  <h6 className="d-flex align-items-center justify-content-center">Till: <h3>6 a.m.</h3></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.photogrid} container`}>
          <div>
            <h1>Precision Cleaning</h1>
            <p>
              Utilizes advanced laser-based technology for precise targeting and
              thorough cleaning, ensuring every nook and cranny of your vehicle
              is addressed.
            </p>
          </div>
          <div>
            <h1>360-Degree Coverage</h1>
            <p>
              Provides comprehensive coverage from all angles, ensuring a
              complete and efficient cleaning process for the entire vehicle.
            </p>
          </div>
          <div>
            <h1>Water Conservation</h1>
            <p>
              Encorporates water-saving technologies to minimize water usage
              while maintaining effective cleaning, promoting environmental
              sustainability.
            </p>
          </div>
          <div>
            <h1>Speed and Efficiency</h1>
            <p>
              Operates with impressive speed, delivering a high-quality wash in
              a fraction of the time compared to traditional methods.
            </p>
          </div>
          <div>
            <h1>Customizable Wash Options</h1>
            <p>
              Offers a range of customizable wash options, allowing users to
              choose the level of service that best suits their preferences and
              vehicle cleaning needs.
            </p>
          </div>
        </div>
      <Footer />
      </div>
      <div className={`${styles.mobile}`}>
        <MobileLaserWash />
      </div>
    </>
  );
};

export default Laser360;
