import React, { useState, useRef } from "react";
import styles from "./MobileDogWash.module.css";
import CustomButton from "../../components/Button/CustomButton";
import Slider from "react-slick";
import Icon1 from "../../images/icons8-dog-heart-90 1.png";
import Icon2 from "../../images/icons8-24-7-open-sign-96 1.png";
import Icon3 from "../../images/icons8-dry-100 1.png";
import PricingOne from "../../images/dog_pricing_one.png";
import PricingTwo from "../../images/dog_pricing_two.png";
import PricingThree from "../../images/dog_pricing_three.png";
import K900Video from "../../media/How To Use the K9000 Self Service Dog Wash.mp4"
import ReactPlayer from "react-player";
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import SplashyImage from "../../images/splashy_image.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import MobileFooter from "../../components/Footer/MobileFooter";


const MobileDogWash = () => {
  const [slideActive, setSlideActive] = useState(1);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const playerRef = useRef(null);

  const toggleVideo = () => {

    if (!videoPlaying) {
        setVideoPlaying(true);
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }
    } else {
        setVideoPlaying(false);
    }
  };

  const toggleMute = () => {
      setIsMuted(!isMuted);
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  const settings = {
    className: "center",
    arrows: false,
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    initialSlide: 1,
    afterChange: (current, next) => {
      setSlideActive(current);
    },
  };
  return (
    <div>
      <MobileNavbar />
      <div className="container mt-3">
        <div className={`${styles.heroSection}`}>
          <h1 className="poppins">
            Where Every Dog <br /> Deserves a Spa Day!
          </h1>
          <p>
            Elevate your pet's grooming experience with our K9000 Dog Wash – a
            perfect blend of convenience and care.
          </p>
          <AnchorLink href="#demovideo">
          <CustomButton
            color={"#FFF"}
            textColor="#20A5B9"
            text="Check The Services"
          />
          </AnchorLink>
        </div>
      </div>

      <div className={`${styles.technology} mt-5`}>
        <h1 className="poppins">K900 dog wash technology</h1>
        <div className="mt-5 mb-4"></div>
        <p className="mb-4">
          Indulge your furry friend in the ultimate pampering with <br /> our
          state-of-the-art K9000 Dog Wash machine. Specially <br /> designed for
          canine comfort, it offers a convenient and <br /> thorough bathing
          experience, ensuring your pet leaves <br /> refreshed and revitalized.
        </p>
        <AnchorLink href="#pricing">
        <CustomButton color={"#22B2C9"} text="Woof Now!" />
        </AnchorLink>
      </div>
      {/* Dog Wash Service Start */}
      <div className={`${styles.serviceContainer} mt-5`}>
        <span className="poppins">Dog Wash Service</span>
        <h2 className="poppins">Features</h2>
        <div className={`${styles.featureCard} mt-5`}>
          <img src={Icon1} />
          <h2 className="poppins">Space and Supplies</h2>
          <p className="poppins">
            From tiny poodles to large Great Danes, we've got the space and
            supplies for a perfect, stress-free bath for your fluffy buddy.
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon2} />
          <h2 className="poppins">Open 24x7</h2>
          <p className="poppins">
            Your busy schedule? No problem! Our self-service dog wash is open
            24/7 for your convenience.
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon3} />
          <h2 className="poppins">Drying Bay </h2>
          <p className="poppins">
            Treat your pets to comfort! Our spacious drying bay allows them to
            stroll around while drying off after a refreshing bath.
          </p>
        </div>
      </div>
      {/* Dog Wash Service End */}
      {/* Pricing Section Start */}
      <div className={`${styles.pricingContainer} mt-5 d-none`} id="pricing">
        <h2 className="poppins mb-3">Self-Serve Services</h2>
        <Slider {...settings}>
          <div>
            <div className={`${styles.pricingCard}`}>
              <img src={PricingThree} className="img-fluid" />
            </div>
          </div>
          <div>
            <div className={`${styles.pricingCard}`}>
              <img src={PricingOne} className="img-fluid" />
            </div>
          </div>
          <div>
            <div className={`${styles.pricingCard}`}>
              <img src={PricingTwo} className="img-fluid" />
            </div>
          </div>
        </Slider>

        <div
          className={`${styles.sliderScroll} ${
            slideActive == 0
              ? "justify-content-start"
              : slideActive == 1
              ? "justify-content-center"
              : "justify-content-end"
          }`}
        >
          <div></div>
        </div>
      </div>
      {/* Pricing Section End */}

      <div className={`${styles.workContainer} mt-5`} id="demovideo">
        <span className="poppins">How it Works?</span>
        <h2 className="poppins">K9000 Dog Wash</h2>
        <div>
        <ReactPlayer 
            ref={playerRef}
            url={K900Video}
            width={'100%'}
            height={'100%'}
            controls={false}
            playing={videoPlaying}
            muted={isMuted}
            onEnded={handleVideoEnd}
          />
          <div>
            <button
              type="button"
              className={`btn btn-light rounded-circle animate__animated ${
                videoPlaying ? "animate__fadeIn" : "animate__fadeOut"
              }`}
              onClick={toggleVideo}
            >
              {videoPlaying ? (
                <FaPause
                  style={{ fill: "#D02329", width: "25px", height: "25px" }}
                />
              ) : (
                <FaPlay
                  style={{ fill: "#D02329", width: "25px", height: "25px" }}
                />
              )}
            </button>
            <button
              type="button"
              className={`btn btn-outline-light rounded-circle animate__animated ${
                videoPlaying ? "animate__fadeIn" : "animate__fadeOut"
              }`}
              onClick={toggleMute}
            >
              {!isMuted ? (
                <GoUnmute
                  style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                />
              ) : (
                <GoMute
                  style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                />
              )}
            </button>
          </div>
        </div>
        <p className="poppins mb-3">
          The K9000 Dog Wash redefines pet pampering with cutting-edge
          technology. Featuring an advanced self-service system, it provides a
          stress-free bathing experience for your furry friend. From
          temperature-controlled water to a gentle yet effective wash, the K9000
          ensures a clean and comfortable spa day for every pup.
        </p>
        <button type="button" className="btn" onClick={toggleVideo}><FaPlay />Play Demo Video</button>
      </div>
      {/* Splashy Start */}
      <div className={`${styles.splashyContainer} mb-5`}>
        <h1 className="poppins">
          Splashy <br /> Tails, <br /> Wagging <br /> Happiness.
        </h1>
        <div></div>
      </div>
      {/* Splashy End */}
      <MobileFooter />
    </div>
  );
};

export default MobileDogWash;
