import React from "react";
import styles from "./Launderette.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import MobileLaunderette from "../../mobile_pages/Launderette/MobileLaunderette";

const Launderette = () => {
    return (
        <>
            <div className={`${styles.desktop}`}>
            <Navbar />
            <div className={`${styles.mainSection} container py-5`}>
                {/* Ticker Start */}
                <div className={`${styles.tickerContainer}`}>
                  <marquee className="poppins">
                    LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON 
                    LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON 
                    LAUNCHING SOON  LAUNCHING SOON  LAUNCHING SOON  
                  </marquee>
                </div>
                {/* Ticker End */}
                {/* Overlay */}
                <div className={`${styles.overlay}`}>
                    <h1>Your Neighborhood Laundry Haven!</h1>
                    <p>Get ready for Your Neighborhood Laundry Haven! 
                        Coming soon to make your laundry days a breeze with top-notch services and a friendly atmosphere.
                    </p>
                    <button type="button" className="btn rounded-pill" onClick={() => {window.location.href="https://g.co/kgs/A4ToABT"}}>Visit Tap&Go</button>
                </div>
            </div>
            <Footer />
            </div>
            <div className={`${styles.mobile}`}>
                <MobileLaunderette />
            </div>
        </>
    );
};

export default Launderette;