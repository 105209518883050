import React, { useRef, useState } from "react";
import styles from "./Enquiry.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import BuisnessPoster from "../../images/Hero Section.png";
import CarW from "../../images/Group 22.png";
import MobileEnquiry from "../../mobile_pages/Enquiry/MobileEnquiry";
import emailjs from '@emailjs/browser';

const Enquiry = () => {
    
  const [ vouchers, setVouchers ] = useState(150);

  (() => {
    
    const forms = document.querySelectorAll('.needs-validation')

    Array.from(forms).forEach(form => {
      form.addEventListener('submit', event => {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
  })()

  const form = useRef();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await emailjs.sendForm('service_mgfv6x2', 'template_wp13a5q', form.current, {
      publicKey: 'JNkKjp0D9WcgjrMek',
    });
    alert("Form Submitted");
  }
  
  return (
        <>
          <div className={`${styles.desktop}`}>
            <Navbar />
            <div className={`${styles.poster} container`}>
                <img src={BuisnessPoster} alt="buisness-poster" className="img-fluid" />
            </div>
            <div className={`${styles.formSection} container-fluid`}>
                <p>Corporate Voucher</p>
                <h1>Fill The Enquiry Form</h1>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <form className="row g-5 needs-validation" ref={form} onSubmit={handleFormSubmit} noValidate>
                            <div className="col-12">
                              <input type="text" placeholder="Your Company Name" className="form-control" id="companyName" name="companyName" required/>
                              <div className="invalid-feedback">
                                Please enter company name
                              </div>
                            </div>
                            <div className="col-12">
                              <input type="text" placeholder="Nature of Buisness" className="form-control" id="buisnessNature" name="buisnessNature" required/>
                              <div className="invalid-feedback">
                                  Please enter natire of buisness
                              </div>
                            </div>
                            <div className="col-8">
                              <input type="text" placeholder="Address" className="form-control" id="address" name="address" required/>
                              <div className="invalid-feedback">
                                Please enter address    
                              </div>
                            </div>
                            <div className="col-4">
                              <input type="text" placeholder="Postcode" className="form-control" id="postcode" name="postcode" required/>
                              <div className="invalid-feedback">
                                Please enter valid postcode    
                              </div>
                            </div>
                            <div className="col-12">
                              <input type="text" placeholder="Contact Name" className="form-control" id="name" name="name" required/>
                              <div className="invalid-feedback">
                                Please enter your name    
                              </div>
                            </div>
                            <div className="col-12">
                              <input type="tel" placeholder="Contact Phone" className="form-control" id="phone" name="phone" required/>
                              <div className="invalid-feedback">
                                Please enter a vaid phone number    
                              </div>
                            </div>
                            <div className="col-12">
                              <input type="email" placeholder="email@gmail" className="form-control" id="email" name="email" required/>
                              <div className="invalid-feedback">
                                Please enter a valid email address    
                              </div>
                            </div>
                            <div className="col-10 py-5">
                              <input type="range" 
                                    className="form-range border-0" 
                                    id="voucher-range"
                                    name="range"
                                    min={0} 
                                    max={300} 
                                    value={vouchers}  
                                    onChange={(e) => setVouchers(e.target.value)} 
                                    required/>
                              <span>{vouchers} Vouchers</span>
                              <div className="invalid-feedback">
                                Please select vouchers    
                              </div>
                            </div>
                            <div className="col-12 border-0 text-start">
                              <button type="submit" className="btn btn-danger rounded-pill">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-md-6">
                      <img src={CarW} alt="car-white" />
                    </div>
                </div>
            </div>
            <Footer />
            </div>
            <div className={`${styles.mobile}`}>
              <MobileEnquiry />
            </div>
        </>
    );
};

export default Enquiry;