import React from "react";
import { useNavigate } from "react-router-dom"; // assuming you're using React Router for navigation

const CustomButton = ({
  color,
  text,
  linked = false,
  link,
  textColor = "white",
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (linked) {
      navigate(link);
    }
    // else block is optional, you can add it if you want to do something when linked is false
  };

  const styles = {
    backgroundColor: color,
    padding: "10px 20px",
    color: textColor,
    border: "none",
    borderRadius: "100px",
    fontWeight: 700,
    fontSize: "12px",
  };

  return (
    <button style={styles} className="poppins" onClick={handleClick}>
      {text}
    </button>
  );
};

export default CustomButton;
