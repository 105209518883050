import React, { useState, useRef } from "react";
import styles from "./MobileEnquiry.module.css";
import CustomButton from "../../components/Button/CustomButton";
import emailjs from "@emailjs/browser";

const MobileEnquiry = () => {
  const [value, setValue] = useState(50);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const form = useRef();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await emailjs.sendForm('service_mgfv6x2', 'template_wp13a5q', form.current, {
      publicKey: 'JNkKjp0D9WcgjrMek',
    });
    alert("Form Submitted");
  }
  return (
    <div>
      {/* Hero Section Start */}
      <div className={`${styles.heroSection}`}>
        <h1 className="poppins">
          Ready To Drive <br /> Corporate <br /> Cleanliness?
        </h1>
        <p>
          Discover Exclusive Bulk Car Wash <br /> Coupons for Your Fleet!
        </p>
      </div>
      {/* Hero Section End */}

      {/* Form Start */}
      <div className={`${styles.formContainer} mt-5`}>
        <span className="poppins">Corporate Voucher</span>
        <h2 className="poppins">Fill The Enquiry Form</h2>
        <form className="container mt-5" ref={form} onSubmit={handleFormSubmit}>
          <input
            placeholder="Your Company Name"
            className={`${styles.customInput} poppins w-100 mb-3`}
            name="companyName"
          />
          <input
            placeholder="Nature Of Bus iness"
            className={`${styles.customInput} poppins w-100 mb-3`}
            name="buisnessNature"
          />
          <div className="row">
            <div className="col-8">
              <input
                placeholder="Address"
                className={`${styles.customInput} poppins w-100 mb-3`}
                name="address"
              />
            </div>
            <div className="col-4">
              <input
                placeholder="Postcode"
                className={`${styles.customInput} poppins w-100 mb-3`}
                name="postcode"
              />
            </div>
          </div>
          <input
            placeholder="Contact Name"
            className={`${styles.customInput} poppins w-100 mb-3`}
            name="name"
          />
          <input
            placeholder="Contact Phone"
            className={`${styles.customInput} poppins w-100 mb-3`}
            name="phone"
          />
          <input
            placeholder="email@gmail.com"
            className={`${styles.customInput} poppins w-100 mb-3`}
            name="email"
          />
          {/* <div className={`${styles.sliderContainer}`}>
            <input
              type="range"
              min="0"
              max="100"
              value="50"
              className={`${styles.slider}`}
              id="myRange"
              onChange={handleChange}
            />
            <div className={`${styles.sliderText}`} id="slider-text">
              50
            </div>
          </div> */}

          <div className="d-flex justify-content-start mt-3">
            <CustomButton text="SUBMIT" color="#D02329" />
          </div>
        </form>
      </div>
      {/* Form End */}
    </div>
  );
};

export default MobileEnquiry;
