import React, { useState, useRef } from "react";
import styles from "./MobileLaserWash.module.css";
import ReactPlayer from "react-player";
import Laserlogo from "../../images/laserWash360.png";
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import Tick from "../../images/check.svg";
import MobileFooter from "../../components/Footer/MobileFooter";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import Laser360Video from "../../media/The LaserWash 360 Plus by PDQ.mp4";
import Slider from "react-slick";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Plans = () => {
  const [slideActive, setSlideActive] = useState(0);
  
  const settings = {
    className: "center",
    arrows: false,
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    initialSlide: 1,
    afterChange: (current, next) => {
      setSlideActive(current);
    },
  };
  return (
    <div className={`${styles.plans} container`} id="plans">
      <h1 className="text-center poppins">Choose a plan that suits your Carwash needs!</h1>
      <Slider {...settings}>
        <div>
          <div className={`${styles.ultimateWash}`}>
            <h5>Ultimate Wash</h5>
            <div>
              <h1>
                $19
                <p>Per Wash</p>
              </h1>
              <h1>
                $28
                <p>2 Washes</p>
              </h1>
            </div>
            <ul>
              <li><img src={Tick} alt="tick" />Underbody Wash</li>
              <li><img src={Tick} alt="tick" />Hot Presoak</li>
              <li><img src={Tick} alt="tick" />Power Rinse</li>
              <li><img src={Tick} alt="tick" />Foaming Polish</li>
              <li><img src={Tick} alt="tick" />Clearcoat Protectant</li>
            </ul>
          </div>
        </div>
        <div>
          <div className={`${styles.deluxeWash}`}>
            <h5>Deluxe Wash</h5>
            <h1>
              $17
              <p>Per Wash</p>
            </h1>
            <ul>
              <li><img src={Tick} alt="tick" />Hot Presoak</li>
              <li><img src={Tick} alt="tick" />Power Rinse</li>
              <li><img src={Tick} alt="tick" />Hot Soap</li>
              <li><img src={Tick} alt="tick" />Rinse & Shine</li>
              <li><img src={Tick} alt="tick" />Spot Free Rinse</li>
            </ul>
          </div>
        </div>
        <div>
          <div className={`${styles.nightOwl}`}>
            <h5>Night Owl</h5>
            <h1>
              $14
              <p>Per Wash</p>
            </h1>
            From
            <h3>10 p.m.</h3>
            Till
            <h3>6 a.m.</h3>
          </div>
        </div>
      </Slider>
      <div
        className={`${styles.scrollBarContainer} ${
          slideActive === 0
            ? "justify-content-start"
            : slideActive === 1
            ? "justify-content-center"
            : "justify-content-end"
        }`}
      >
        <div></div>
      </div>
    </div>
  );
};

const MobileLaserWash = () => {
  const [videoPlaying, setVideoPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const playerRef = useRef(null);

  const togglePause = () => {

    if (videoPlaying) {
        setVideoPlaying(false);
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }
    } else {
        setVideoPlaying(true);
    }
  };

  const toggleMute = () => {
      setIsMuted(!isMuted);
  };

  return (
    <>
    <div className={`${styles.main}`}>
      <MobileNavbar whiteBackground={true} />
        <div className={`${styles.topSection} container`}>
          <div className="row">
            <div className="col-12">
              <img src={Laserlogo} alt="laser360" width={279} height={107} />
            </div>
            <div className="col-12">
              <h1>Precision in Every Wash</h1>
              <p>
                Introducing LaserWash360 Technology for a Touchless, Efficient,
                and Superior Car Cleaning Experience.
              </p>
              <AnchorLink href="#plans">
              <button type="button" className="btn rounded-pill">
                Check More Details
              </button>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className={`${styles.videoSection} container-fluid py-5`}>
          <div className="card border-0">
            <ReactPlayer
              ref={playerRef}
              url={Laser360Video}
              width={'100%'}
              height={'100%'}
              controls={false}
              playing={videoPlaying}
              loop={true}
              muted={isMuted}
              className="animate_animated animate__fadeIn animate__fadeOut animate_slower"
            />
          </div>
          <div>
            <button
                type="button"
                className="btn btn-light rounded-circle"
                onClick={togglePause}
            >
                {videoPlaying ? (
                    <FaPause style={{ fill:"#D02329", width:"13.5px", height:"13.5px" }}/> 
                    ) : ( 
                    <FaPlay style={{ fill:"#D02329", width:"13.5px", height:"13.5px" }}/>
                )}
            </button>
            <button
                type="button"
                className="btn btn-outline-dark rounded-circle"
                onClick={toggleMute}
            >
                {!isMuted ? ( 
                    <GoUnmute style={{ fill:"#000", width:"15px", height:"15px" }}/> 
                    ) : ( 
                    <GoMute style={{ fill:"#000", width:"15px", height:"15px" }}/>
                )}
            </button>
          </div>
        </div>
        <Plans />
        <div className={`${styles.photogrid} container`}>
          <div>
            <h1>Precision Cleaning</h1>
            <p>
              Utilizes advanced laser-based technology for precise targeting and
              thorough cleaning, ensuring every nook and cranny of your vehicle
              is addressed.
            </p>
          </div>
          <div>
            <h1>Water Conservation</h1>
            <p>
              Encorporates water-saving technologies to minimize water usage
              while maintaining effective cleaning, promoting environmental
              sustainability.
            </p>
          </div>
          <div>
            <h1>360-Degree Coverage</h1>
            <p>
              Provides comprehensive coverage from all angles, ensuring a
              complete and efficient cleaning process for the entire vehicle.
            </p>
          </div>
          <div>
            <h1>Speed and Efficiency</h1>
            <p>
              Operates with impressive speed, delivering a high-quality wash in
              a fraction of the time compared to traditional methods.
            </p>
          </div>
          <div>
            <h1>Customizable Wash Options</h1>
            <p>
              Offers a range of customizable wash options, allowing users to
              choose the level of service that best suits their preferences and
              vehicle cleaning needs.
            </p>
          </div>
        </div>
        <MobileFooter />
      </div>
    </>
  );
};

export default MobileLaserWash;
