import React, { useRef } from "react";
import styles from "./MobileFooter.module.css";
import Logo from "../../images/Frame 1.svg";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import location from "../../images/location_on.svg";
import call from "../../images/call.svg";
import mail from "../../images/email.svg"; 
import facebook from "../../images/ic_baseline-facebook.svg";
import insta from "../../images/ri_instagram-fill.svg";
import twitter from "../../images/mdi_twitter.svg";

const MobileFooter = () => {
  const navigate = useNavigate();
  const form = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();

		await emailjs.sendForm('service_mgfv6x2', 'template_oyjfdgl', form.current, {
			publicKey: 'JNkKjp0D9WcgjrMek',
		  });
		  alert("Thanks for Subscribing!");
	};

  return (
    <footer className={`${styles.Footer} container-fluid`}>
      <div className={`${styles.newsletterSection} text-start`}>
				<h1>Join a Newsletter</h1>
				<form ref={form} onSubmit={handleSubmit}>
					<label for='newsletterEmail' className='form-label'>Your Email</label>
					<input type='email' 
						className='form-control' 
						placeholder='Enter Your Email' 
						name='email'
						id='newsletterEmail' />
					<button type='submit' className='btn rounded-pill' onClick={handleSubmit}>Subscribe</button>
				</form>
			</div>
      <div className={`${styles.logoSection} text-start`}>
					<img src={Logo} alt='logo' width={108.5} height={63} onClick={() => navigate('/')} />
					<div>
						<button type='button' className='btn rounded-circle'><img src={facebook} /></button>
						<button type='button' className='btn rounded-circle'><img src={insta} /></button>
						<button type='button' className='btn rounded-circle'><img src={twitter} /></button>
					</div>
			</div>
      <div className={`${styles.para} text-start`}>
        <p>
					We make sure to give your car the pempering it deserves in BubBlast.
				</p>
      </div>
      <div className={`${styles.quickLinkSection}`}>
        <div>
          <h1>Support</h1>
          <ul>
            <li>Service FAQ</li>
            <li>User Policy</li>
            <li>Privacy Policy</li>
            <li>Members Plicy</li>
            <li>Customer Support</li>
          </ul>
        </div>
        <div>
          <h1>Company</h1>
          <ul>
            <li onClick={() => navigate('/about')}>About Us</li>
            <li onClick={() => navigate('/')}>Our Services</li>
            <li>Pricing Plan</li>
          </ul>
        </div>
      </div>
      <div className={`${styles.contact} text-start`}>
				<h1>Get in Touch</h1>
				<ul>
					<li className='d-flex column-gap-2'><img src={location} /> 1470-1474 Sydney Rd,<br /> Campbellfield VIC 3061,<br /> Australia</li>
					<li className='d-flex column-gap-2'><img src={mail} />hello@tagcw.com.au</li>
					<li className='d-flex column-gap-2'><img src={call} />+61 410 910 644</li>
				</ul>
			</div>
      <div>
        <p className="text-start">
          Copyright &copy; {new Date().getFullYear()} All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default MobileFooter;
