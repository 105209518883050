import React from "react";
import { Map, APIProvider, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import styles from "./MobileBusiness.module.css";
import CustomButton from "../../components/Button/CustomButton";
import Slider from "react-slick";
import tapNgo from "../../images/map-tapngo.png";
import circle from "../../images/map-circlerGad.png";
import sass from "../../images/map-sass.png";
import daf from "../../images/map-daf.png";
import cdw from "../../images/map-cdw.png";
import elephant from "../../images/map-elephant.png";
import sf from "../../images/map-sf.png";
import Vector1 from "../../images/Vector.png";
import Vector2 from "../../images/Vector2.png";
import Vector3 from "../../images/Vector 3.png";
import Vector4 from "../../images/Vector 4.png";
import Vector5 from "../../images/Vector 5.png";
import Vector6 from "../../images/Payoneer.png";
import Vector7 from "../../images/Firebase.png";
import Vector8 from "../../images/CDW.png";
import Vector9 from "../../images/Capsule Pharmacy.png";
import Vector10 from "../../images/NuxtJS.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import MobileFooter from "../../components/Footer/MobileFooter";

const MobileBusiness = () => {
  const settings = {
    className: "slider variable-width",
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    variableWidth: true,
  };
  const settings2 = {
    className: "slider variable-width",
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1800,
    autoplaySpeed: 1800,
    cssEase: "linear",
    variableWidth: true,
  };

  const mainPosition = { lat: -37.68637725330796, lng: 144.9587401963364 };
  const Position1 = { lat: -37.678060351616836, lng: 144.94404025121693 };
  const Position2 = { lat: -37.69142796106712, lng: 144.94172480325042 };
  const Position3 = { lat: -37.68190566362559, lng: 144.94168684508705 };
  const Position4 = { lat: -37.67673847958072, lng: 144.9679918523132 };
  const Position5 = { lat: -37.68355788490672, lng: 144.97049709109663 };
  const Position6 = { lat: -37.69238913451279, lng: 144.97357170233087 };
  
  return (
    <div>
      <MobileNavbar />
      <div className={`${styles.heroSection}`}>
        <h1 className="poppins">
          Empowering Locals, <br /> Celebrating <br /> Commmunity!
        </h1>
        <p>Discover the Businesses We Proudly Support.</p>
        <AnchorLink href="#community">
        <CustomButton color="#D02329" text="View Businesses" />
        </AnchorLink>
      </div>
      <APIProvider apiKey={'AIzaSyBZoct8K8bPkVmIPKWSrzw_TC4Iwth-cic'}>
          <div className={`${styles.mapSection}`}>
            <Map defaultZoom={13.5} defaultCenter={mainPosition} mapId={'e6e8821d00ab469d'}>
                <AdvancedMarker position={mainPosition}>
                    <img src={tapNgo} className={`${styles.marker}`} />
                </AdvancedMarker>
                <AdvancedMarker position={Position1}>
                    <img src={sf} className={`${styles.marker}`} />
                </AdvancedMarker>
                <AdvancedMarker position={Position2}>
                    <img src={sass} className={`${styles.marker}`} />
                </AdvancedMarker>
                <AdvancedMarker position={Position3}>
                    <img src={circle} className={`${styles.marker}`} />
                </AdvancedMarker>
                <AdvancedMarker position={Position4}>
                    <img src={daf} className={`${styles.marker}`} />
                </AdvancedMarker>
                <AdvancedMarker position={Position5}>
                    <img src={cdw} className={`${styles.marker}`} />
                </AdvancedMarker>
                <AdvancedMarker position={Position6}>
                    <img src={elephant} className={`${styles.marker}`} />
                </AdvancedMarker>
            </Map>
        </div>
        </APIProvider>
      <div className={`${styles.clientsSection} mt-5`} id="community">
        <span className="poppins">Our Commmunity</span>
        <h2 className="poppins">Business On Site</h2>
        <Slider {...settings} className="mt-5">
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector1} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector2} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector3} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector4} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector5} width={130} />
          </div>
        </Slider>
        <Slider {...settings2} className="mt-5 mb-5">
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector6} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector7} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector8} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector9} width={130} />
          </div>
          <div
            style={{ width: 200 }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={Vector10} width={130} />
          </div>
        </Slider>
      </div>
      <MobileFooter />
    </div>
  );
};

export default MobileBusiness;
