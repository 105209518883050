import React, { useState, useRef } from "react";
import styles from "./MobileCarWash.module.css";
import CustomButton from "../../components/Button/CustomButton";
import Icon1 from "../../images/ic_baseline-do-not-touch.png";
import Icon2 from "../../images/icons8-hot-springs-100 (1) 1.png";
import Icon3 from "../../images/icons8-water-52 2.png";
import Icon4 from "../../images/icons8-hourglass-96 1.png";
import Icon5 from "../../images/icons8-car-cleaning-100 1.png";
import Icon6 from "../../images/icons8-vehicle-insurance-64 2.png";
import mobileSqueaky from "../../images/mobile_squeaky.png";
import ImageOne from "../../images/carwashimage1.png";
import ImageTwo from "../../images/carwashimage2.png";
import ImageThree from "../../images/carwashimage3.png";
import ImageFour from "../../images/carwashimage4.png";
import PricingOne from "../../images/pricing_one.png";
import PricingTwo from "../../images/pricing_two.png";
import PricingThree from "../../images/pricing_three.png";
import Video from "../../media/The LaserWash 360 Plus by PDQ.mp4";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { FaPlay, FaPause } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import MobileFooter from "../../components/Footer/MobileFooter";
import { useNavigate } from "react-router-dom";


const MobileCarWash = () => {
  const [slideActive, setSlideActive] = useState(1);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const toggleVideo = () => {

    if (!videoPlaying) {
        setVideoPlaying(true);
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }
    } else {
        setVideoPlaying(false);
    }
  };

  const toggleMute = () => {
      setIsMuted(!isMuted);
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  const settings = {
    className: "center",
    arrows: false,
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    initialSlide: 1,
    afterChange: (current, next) => {
      setSlideActive(current);
    },
  };
  return (
    <>
      <MobileNavbar />
      {/* Hero Section Start */}
      <div className="container mt-5">
        <div className={`${styles.heroSection}`}>
          <h2 className="poppins">
            Where Every Wash Revives Your Ride's Radiance!
          </h2>
          <p>
            Revitalize your ride at Tap & Go Car Wash! Our cutting-edge
            technology ensures leaving your vehicle shining and protected.
            Experience the ultimate car care – because your journey deserves a
            spotless start.
          </p>
          <AnchorLink href="#demovideo">
          <CustomButton text="Check The Services" color="#D02329" />
          </AnchorLink>
        </div>
      </div>
      {/* Hero Section End */}

      {/* Laser 360 Section Start */}
      <div className="container mt-5">
        <div className={`${styles.laserPoster}`} onClick={() => navigate('/mobilelaserwash')}></div>
      </div>
      {/* Laser 360 Section Start */}

      {/* Pricing Section Start */}
      <div className={`${styles.pricingContainer} mt-5 d-none`}>
        <h2 className="poppins mb-5">Self-Serve Services</h2>
        <Slider {...settings}>
          <div>
            <div className={`${styles.pricingCard}`}>
              <img src={PricingThree} className="img-fluid" />
            </div>
          </div>
          <div>
            <div className={`${styles.pricingCard}`}>
              <img src={PricingOne} className="img-fluid" />
            </div>
          </div>
          <div>
            <div className={`${styles.pricingCard}`}>
              <img src={PricingTwo} className="img-fluid" />
            </div>
          </div>
        </Slider>

        <div
          className={`${styles.sliderScroll} ${
            slideActive == 0
              ? "justify-content-start"
              : slideActive == 1
              ? "justify-content-center"
              : "justify-content-end"
          }`}
        >
          <div></div>
        </div>
      </div>
      {/* Pricing Section End */}
      
      {/* Self-Serve Bay Section Start */}
      <div className={`${styles.infoSection} mt-5`} id="services">
        <h2 className="poppins">
          Get your car squeaky <br /> clean!
        </h2>
        <div style={{ backgroundImage: `url(${mobileSqueaky})` }}></div>
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Take control of your car's shine at Tap & Go!
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Our self-service car wash stations are equipped with <br /> everything
          you need for a spotless finish. From foaming <br /> brushes to
          high-pressure hoses, our user-friendly facilities <br /> make it easy
          to give your vehicle the care it deserves.
        </span>
        <br />
        <br />
        <span className={`${styles.paraText} poppins mt-5`}>
          Stop by and experience the satisfaction of a self car wash <br /> that
          puts you in the driver's seat of cleanliness.
        </span>
      </div>
      {/* Self-Serve Bay Section End */}


      {/* Car Wash Service Start */}
      <div className={`${styles.serviceContainer} mt-5`}>
        <span className="poppins">Car Wash Service</span>
        <h2 className="poppins">Features</h2>
        <div className={`${styles.featureCard} mt-5`}>
          <img src={Icon1} />
          <h2 className="poppins">Contact Less</h2>
          <p className="poppins">
            Experience hassle free service with our contactless feature – a
            touchless, secure solution for a safer and more convenient car wash
            experience at Tap & Go.
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon2} />
          <h2 className="poppins">Hot Water Wash</h2>
          <p className="poppins">
            Our advanced technology uses heated water to dissolve grime,
            providing an effective and thorough clean for your vehicle."
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon3} />
          <h2 className="poppins">Distilled Water </h2>
          <p className="poppins">
            Enjoy a superior clean with our car wash's distilled water, free
            from contaminants. Give your vehicle the pristine care it deserves
            for a sparkling finish.
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon4} />
          <h2 className="poppins">No Downtime</h2>
          <p className="poppins">
            Uninterrupted service with our 'No Downtime' feature. Your
            convenience is our priority, ensuring swift and seamless experiences
            without any interruptions."
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon5} />
          <h2 className="poppins">No Downtime</h2>
          <p className="poppins">
            With our Self-Serve Bay – take control with foaming brushes and
            high-pressure hoses, ensuring a personalized and thorough cleaning
            experience.
          </p>
        </div>
        <div className={`${styles.featureCard} mt-3`}>
          <img src={Icon6} />
          <h2 className="poppins">Self-serve Bay</h2>
          <p className="poppins">
            With our Self-Serve Bay – take control with foaming brushes and
            high-pressure hoses, ensuring a personalized and thorough cleaning
            experience.
          </p>
        </div>
      </div>
      {/* Car Wash Service End */}

      {/* How it works start */}
      <div className={`${styles.worksContainer} mt-5 mb-5 `} id="demovideo">
        <span className="poppins">How it Works?</span>
        <h2 className="poppins mb-5">Self-Serve Car Wash</h2>
        <div>
          <ReactPlayer 
            ref={playerRef}
            url={Video}
            width={'100%'}
            height={'100%'}
            controls={false}
            playing={videoPlaying}
            muted={isMuted}
            onEnded={handleVideoEnd}
          />
          <div>
            <button
              type="button"
              className={`btn btn-light rounded-circle animate__animated ${
                videoPlaying ? "animate__fadeIn" : "animate__fadeOut"
              }`}
              onClick={toggleVideo}
            >
              {videoPlaying ? (
                <FaPause
                  style={{ fill: "#D02329", width: "25px", height: "25px" }}
                />
              ) : (
                <FaPlay
                  style={{ fill: "#D02329", width: "25px", height: "25px" }}
                />
              )}
            </button>
            <button
              type="button"
              className={`btn btn-outline-light rounded-circle animate__animated ${
                videoPlaying ? "animate__fadeIn" : "animate__fadeOut"
              }`}
              onClick={toggleMute}
            >
              {!isMuted ? (
                <GoUnmute
                  style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                />
              ) : (
                <GoMute
                  style={{ fill: "#FFFF", width: "25px", height: "25px" }}
                />
              )}
            </button>
          </div>
        </div>
        <p className="poppins mt-5 mb-5">
          Make your vehicle shine by bringing it down to our self-service car
          wash. You can count on our car wash stations to be big enough to
          accomodate any sort of vehicle. Both wash and vacuum services are
          available
        </p>
        <button type="button" className="btn" onClick={toggleVideo}><FaPlay />Play Demo Video</button>
      </div>
      {/* How it works End */}

      {/* Value Start */}
      <div className={`${styles.valueContainer} mt-5`}>
        <div></div>
        <div>
          <h2 className="poppins">BEST VALUE</h2>
          <p>
            Grab Our Exclusive Car Wash Coupon and Enjoy a Sparkling Clean at
            Ride a Special Price!
          </p>
          <h3 className="poppins">Revitalize Your Ride for Less!</h3>
          <h4 className="poppins">
            <b>$150</b> Pre-Paid Autowash
          </h4>
          <label>Quantity</label>
          <div>
            <input style={{ marginRight: "10px", padding: "10px 10px" }} />
            For $300
          </div>
          <CustomButton text="Buy Now" color="#D02329" />
        </div>
      </div>
      {/* Value End */}

      {/* Customers Start */}
      <div className="container mt-5 mb-4">
        <span className="poppins">Happy Customer</span>
        <h2 className="poppins">Connect With Us</h2>

        <div className={`${styles.squareImageContainer}`}>
          <img src={ImageOne} className="img-fluid" />
        </div>
        <div className={`${styles.portraitImageContainer}`}>
          <img src={ImageTwo} className="img-fluid" />
        </div>
        <div className={`${styles.portraitImageContainer}`}>
          <img src={ImageThree} className="img-fluid" />
        </div>
        <div className={`${styles.squareImageContainer}`}>
          <img src={ImageFour} className="img-fluid" />
        </div>
      </div>
      {/* Customers End */}
      <MobileFooter />
    </>
  );
};
export default MobileCarWash;
