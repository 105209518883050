import React from "react";
import MobileAbout from "../../mobile_pages/About/MobileAbout";
import styles from "./About.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Icon1 from "../../images/ion_location-outline.png";
import Icon2 from "../../images/hose.png";
import Icon3 from "../../images/handshake.png";
import position1 from "../../images/position1.png";
import position2 from "../../images/position2.png";
import position3 from "../../images/position3.png";
import position4 from "../../images/position4.png";
import Vector1 from "../../images/BurgerTempt.png";
import Vector2 from "../../images/Sienna's.png";
import Vector3 from "../../images/Bigfoot.png";
import Vector4 from "../../images/SpudSpot.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Map, APIProvider, AdvancedMarker } from "@vis.gl/react-google-maps";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from "react-router-dom";

const About = () => {
    
    const navigate = useNavigate();

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      }
    };

    const mainPosition = { lat: -37.68651687321478, lng: 144.95875353625811 };
    const Position1 = { lat: -37.68659217369824, lng: 144.95831640365154 }; 
    const Position2 = { lat: -37.68663361078004, lng: 144.95821410240302 };
    const Position3 = { lat: -37.68675078946663, lng: 144.95813737646662 };
    const Position4 = { lat: -37.686839451438464, lng: 144.9583874461852 };
    
    return (
        <>
        <div className={`${styles.desktopAbout}`}>
        <Navbar />
        <div className={`${styles.topBackground} z-n1`}></div>
        <div className={`${styles.topSection} container`}>
                <h1>
                    Why make multiple stops around town?
                </h1>
                <p>
                    When you can just run your errands from under one roof!
                </p>
                <button type="button" className="btn rounded-pill" onClick={() => navigate('/')}>View Our Services</button>
        </div>
        <div className={`${styles.poster} container`}>
        <div className="d-flex flex-row">
          {/* <div className="col-5">
            <h2>
                We offer 3 services
            </h2>
            <p>
                So that you can efficiently 
            </p>
            <h1>
                Manage Your Time!
            </h1>
            <div className="d-flex flex-column mt-5">
                <div className="d-flex align-items-center justify-cotent-center gap-2">
                    <img src={CarIcon} alt="caricon" className="img-fluid" />
                    <p>Get your car squeaky clean!</p>
                </div>
                <div className="d-flex align-items-center justify-cotent-center gap-2">
                    <img src={PetIcon} alt="caricon" className="img-fluid" />
                    <p>Give your pet a beautiful wash and scrub! </p>
                </div>
                <div className="d-flex align-items-center justify-cotent-center gap-2">
                    <img src={LaunIcon} alt="caricon" className="img-fluid" />
                    <p>Let us take care of your laundry needs!</p>
                </div>
            </div>
          </div> */}
        </div>
        </div>
        <div className={`${styles.reasons} container`}>
            <h1>
                Why Choose Us ?
            </h1>
            <div className="row row-cols-3 g-5">
                <div className="col">
                    <div className="card h-100">
                        <img src={Icon1} className="img-fluid" alt="icon-location" />
                        <div className="card-body">
                            <h5 className="card-title">One Stop Destination</h5>
                            <p className="card-text">Effortless errands: laundry, 
                                pet grooming, and an automated car wash—all while sipping coffee!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100">
                        <img src={Icon2} className="img-fluid" alt="icon-water-hose" />
                        <div className="card-body">
                            <h5 className="card-title">Top Notch Equipment</h5>
                            <p className="card-text">Experience top-tier service with our cutting-edge equipment, 
                                ensuring a guarantee of quality for all your needs!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100">
                        <img src={Icon3} className="img-fluid" alt="icon-contactless" />
                        <div className="card-body">
                            <h5 className="card-title">Contactless Service</h5>
                            <p className="card-text">Stay safe with Tap & Go and enjoy contactless service 
                                & payment for your convenience in the era of COVID-19!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`${styles.lastSection} container`}>
            <h1>
                Empowering Locals, Celebrating Community!
            </h1>
            <p>
                "As proud members of the local community, we're committed to supporting fellow businesses. 
                Soon, we'll have a dedicated board for local companies and entrepreneurs to showcase their flyers, 
                posters, or business cards. If you're a local business owner looking to promote your products or services, 
                drop by and chat with us. We'd be delighted to feature your business on our board for our customers to discover!"
            </p>
            <div className="d-flex gap-3">
                <button type="button" className="btn rounded-pill" onClick={() => {window.location.href="https://g.co/kgs/A4ToABT"}}>View Businesses</button>
                <button type="button" className="btn rounded-pill" onClick={() => {window.location.href="https://g.co/kgs/A4ToABT"}}>Get There</button>
            </div>
        </div>
        <APIProvider apiKey={'AIzaSyBZoct8K8bPkVmIPKWSrzw_TC4Iwth-cic'}>
          <div className={`${styles.mapSection}`}>
            <Map defaultZoom={19} defaultCenter={mainPosition} mapId={'e6e8821d00ab469d'}>
                <AdvancedMarker position={mainPosition}>
                </AdvancedMarker>
                <AdvancedMarker position={Position1}>
                    <img src={position1} width={200} height={200} />
                </AdvancedMarker>
                <AdvancedMarker position={Position2}>
                    <img src={position2} width={200} height={200} />
                </AdvancedMarker>
                <AdvancedMarker position={Position3}>
                    <img src={position3} width={200} height={200} />
                </AdvancedMarker>
                <AdvancedMarker position={Position4}>
                    <img src={position4} width={200} height={200} />
                </AdvancedMarker>
            </Map>
        </div>
        </APIProvider>
        <div className={`${styles.communitySection} container-fluid`} id="community">
            <h1>Businesses On Site</h1>
            <Carousel 
                responsive={responsive}
                arrows={false}
                infinite={false}
                centerMode={false}
                itemClass="d-flex justify-content-center align-items-center mx-1"
                >
                    <img src={Vector1} alt="vecto1" className="img-fluid" />
                    <img src={Vector2} alt="vecto1" className="img-fluid" />
                    <img src={Vector3} alt="vecto1" className="img-fluid" />
                    <img src={Vector4} alt="vecto1" className="img-fluid" />
            </Carousel>
        </div>
        <Footer />
        </div>
        <div className={`${styles.mobileAbout}`}>
            <MobileAbout />
        </div>
        </>
    );
};

export default About;