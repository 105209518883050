import React, { useState, useRef } from "react";
import styles from "./DogWash.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { GoMute, GoUnmute } from "react-icons/go";
import { FaPlay, FaPause } from "react-icons/fa";
import K900 from "../../images/k9000.png";
import Icon1 from "../../images/icons8-dog-heart-90 1.png";
import Icon2 from "../../images/icons8-24-7-open-sign-96 1.png"; 
import Icon3 from "../../images/icons8-dry-100 1.png"; 
import DogOnTop from "../../images/dogAbove.png";
import TickW from "../../images/VectorW.png";
import TickB from "../../images/VectorB.png";
import Dog1 from "../../images/dogG1.png";
import Dog2 from "../../images/dogG2.png";
import Dog3 from "../../images/dogG3.png";
import ReactPlayer from "react-player";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Video from "../../media/How To Use the K9000 Self Service Dog Wash.mp4";
import MobileDogWash from "../../mobile_pages/DogWash/MobileDogWash";

const DogWash = () => {
	const [videoPlaying, setVideoPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const playerRef = useRef(null);

  const toggleVideo = () => {
    if (!videoPlaying) {
      setVideoPlaying(true);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
    } else {
      setVideoPlaying(false);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

    return (
        <>
            <div className={`${styles.desktopDogwash}`}>
            <Navbar />
            <div className={`${styles.topSection} container`}>
                <div className="d-flex flex-row">
                    <div className="col-10">
                        <h1>
                            Where Every Dog Deserves a Spa Day!
                        </h1>
                        <p>
                            Elevate your pet's grooming experience with our K9000 Dog Wash – a perfect blend of convenience and care.
                        </p>
												<AnchorLink href="#demovideo">
                        <button type="button" className="btn rounded-pill">Check the Services</button>
												</AnchorLink>
										</div>
                </div>
            </div>
            <div className={`${styles.k900} container-fluid`}>
                <div className="d-flex">
                    <div className="col-6">
                        <img src={K900} alt="k900-technology" className="img-fluid" />
                    </div>
                    <div className="col-6">
                        <h1>
                            K900 dog wash technology
                        </h1>
                        <p>
                            Indulge your furry friend in the ultimate pampering with our state-of-the-art K9000 Dog Wash machine. 
                            Specially designed for canine comfort, it offers a convenient and thorough bathing experience, 
                            ensuring your pet leaves refreshed and revitalized.
                        </p>
												<AnchorLink href="#pricing">
                        <button type="button" className="btn rounded-pill">Woof Now!</button>
												</AnchorLink>
										</div>
                </div>
            </div>
            <div className={`${styles.feature} container`}>
                <p>Dog Wash Service</p>
                <h1>Features</h1>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100">
                            <img src={Icon1} className="img-fluid" alt="icon-dog-heart" />
                            <div className="card-body">
                                <h5 className="card-title">Space and Supplies</h5>
                                <p className="card-text">From tiny poodles to large Great Danes, 
                                    we've got the space and supplies for a perfect, 
                                    stress-free bath for your fluffy buddy.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={Icon2} className="img-fluid" alt="icon-24x7-open" />
                            <div className="card-body">
                                <h5 className="card-title">Open 24x7</h5>
                                <p className="card-text">Your busy schedule? No problem! 
                                    Our self-service dog wash is open 24/7 for your convenience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={Icon3} className="img-fluid" alt="icon-dry-heat" />
                            <div className="card-body">
                                <h5 className="card-title">Drying Bay</h5>
                                <p className="card-text">Treat your pets to comfort! 
                                    Our spacious drying bay allows them to stroll around while drying off after a refreshing bath.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={DogOnTop} alt="dog-on-top" className="img-fluid" />
            </div>
            <div className={`${styles.packages} container d-none`} id="pricing">
                <h1>Dog Wash Packages</h1>
                <div className="row row-cols-1 row-cols-md-3 g-4 text-start">
                    <div className="col">
                        <div className="card">
                            <div className="card-header border-0" style={{ background:"unset" }}>
                                <p>Turbo Vacuum</p>
                                <h1 style={{ color:'#22B2C9' }}>$20</h1>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <li className="list-group-item">Turbo Mode</li>
                                    <li className="list-group-item">Normal Mode</li>
                                    <li className="list-group-item">Fragrance Mode</li>
                                </ul>
                                <p>
                                    Pick from our range of flavors:<br />
                                    New car, fresh mint and berry
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card" style={{ backgroundColor:'#22B2C9' }}>
                            <div className="card-header border-0" style={{ background:"unset" }}>
                                <p style={{ color: '#FFFFFF' }}>Deluxe Wash</p>
                                <h1>$16</h1>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        Side Blast
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Wheel Cleaning
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Pre-soak 1 - heated
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>    
                                    </li>
                                    <li className="list-group-item">
                                        Pre-soak 2 - heated
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        High Pressure rinse
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Tri-colour foaming
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Spot free rinse
                                        <span><img src={TickW} alt="tick" className="img-fluid" /></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <div className="card-header border-0" style={{ background:"unset" }}>
                                <p>Ultimate Wash</p>
                                <h1 style={{ color:'#22B2C9' }}>$18</h1>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        Side blast - Underbody wash
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Wheel cleaning
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Pre-soak 1 - heating
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Pre-soak 2 - heating
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        High Pressure rinse
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Super Sealent
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Spot free rinse
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                    <li className="list-group-item">
                                        Extreme blowers
                                        <span><img src={TickB} alt="tick" className="img-fluid" /></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.demo} container`} id="demovideo">
							<div className="card border-0">
								<ReactPlayer
									ref={playerRef}
									url={Video}
									width={"100%"}
									height={"100%"}
									controls={false}
									playing={videoPlaying}
									onEnded={handleVideoEnd}
									muted={isMuted}
								/>
                <div className="card-img-overlay">
            			<div className="d-flex flex-column">
            			  <div
            			    id="textOnVideo"
            			    className={`animate__animated ${
            			      videoPlaying ? "animate__fadeOut" : "animate__fadeIn"
            			    }`}
            			  >
            			    <p>How it Works ?</p>
            			    <h1>K9000 Dog Wash</h1>
            			    <p>
												The K9000 Dog Wash redefines pet pampering with cutting-edge technology. 
												Featuring an advanced self-service system, it provides a stress-free bathing experience for your furry friend. 
												From temperature-controlled water to a gentle yet effective wash, 
												the K9000 ensures a clean and comfortable spa day for every pup.
            			    </p>
            			    <button
            			      type="button"
            			      className="btn rounded-pill"
            			      onClick={toggleVideo}
            			    >
            			      <FaPlay /> Play Demo Video
            			    </button>
            			  </div>
            			  <div id="buttonOnVideo">
            			    <button
            			      type="button"
            			      className={`btn btn-light rounded-circle animate__animated ${
            			        videoPlaying ? "animate__fadeIn" : "animate__fadeOut"
            			      }`}
            			      onClick={toggleVideo}
            			    >
            			      {videoPlaying ? (
            			        <FaPause
            			          style={{ fill: "#D02329", width: "25px", height: "25px" }}
            			        />
            			      ) : (
            			        <FaPlay
            			          style={{ fill: "#D02329", width: "25px", height: "25px" }}
            			        />
            			      )}
            			    </button>
            			    <button
            			      type="button"
            			      className="btn btn-outline-light rounded-circle"
            			      onClick={toggleMute}
            			    >
            			      {!isMuted ? (
            			        <GoUnmute
            			          style={{ fill: "#FFFF", width: "25px", height: "25px" }}
            			        />
            			      ) : (
            			        <GoMute
            			          style={{ fill: "#FFFF", width: "25px", height: "25px" }}
            			        />
            			      )}
            			    </button>
            			  </div>
            			</div>
          			</div>
        			</div>
      			</div>
            <div className={`${styles.visitUs} container-fluid`}>
                <div className="d-flex">
                    <div className="col-6">
                        <h1>
                            Splashy Tails, Wagging Happiness.
                        </h1>
                        <p>
                            Pamper your pets with our top-notch grooming space, 
                            equipment and technology ensuring tails are not just dry but delightfully happy!
                        </p>
                        <button type="button" className="btn rounded-pill" onClick={() => {window.location.href="https://g.co/kgs/A4ToABT"}}>Visit Us</button>
                    </div>
                    <div className="col-6">
                        <div className={`${styles.photogrid}`}>
                            <div><img src={Dog1} alt="dog1" className="img-fluid" /></div>
                            <div><img src={Dog2} alt="dog1" className="img-fluid" /></div>
                            <div><img src={Dog3} alt="dog1" className="img-fluid" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </div>
            <div className={`${styles.mobileDogwash}`}>
              <MobileDogWash />
            </div>
        </>
    );
};

export default DogWash;